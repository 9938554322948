// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_fabricCanvas_objDeselct", inline: true };
const loggerMsgs = {
  eventStart: ["the fabric canvas selection has been cleared"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_fabricCanvas_objDeselct(EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Stop, if in "drawing" mode...
  if (this.state.mode === "drawing") return;

  // Reset mode to "ready"...
  this.setState({ mode: "ready" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
