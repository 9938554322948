// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_fabric_canvasPan", inline: true };
const loggerMsgs = {
  initStart: ["init. fabric_canvasPan"],
  initSuccess: ["init. fabric_canvasPan", "init. success"],
  noValidDOMEl: ["init. fabric_canvasPan", "no valid DOM element provided"],
  noValidFabricInst: ["init. fabric_canvasPan", "no valid fabric instance provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_fabric_canvasPan(
  FABRICINST,
  CONST = {
    render_canvasEdgeMarkers: false,
  }
) {
  // Setup...
  const { panConfig } = this.options;
  const { maxPanDist = { x: 1000, y: 1000 } } = panConfig;
  const { render_canvasEdgeMarkers = false } = CONST;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!FABRICINST) return this.cancel_featInit(loggerMsgs.noValidFabricInst);

  // Record spacebar being pressed...
  document.body.addEventListener("keydown", (e) => {
    if (e.code === "Space" || e.key === " ") {
      this.modules.fabric.spacebarPressed = true;
      this.ref.panBtn.setAttribute("data-is-selected", "true");
      FABRICINST.getObjects().forEach((item) => (item.selectable = false));
    }
  });
  document.body.addEventListener("keyup", (e) => {
    if (e.code === "Space" || e.key === " ") {
      this.modules.fabric.spacebarPressed = false;
      this.ref.panBtn.setAttribute("data-is-selected", "false");
      FABRICINST.getObjects().forEach((item) => (item.selectable = true));
    }
  });

  // When spacebar is being pressed on mouse down, enable panning...
  FABRICINST.on("mouse:down", (opt) => {
    const eventData = opt.e;
    const executePan = this.modules.fabric.spacebarPressed || this.state.mode === "panning";
    if (executePan) {
      this.modules.fabric.isPanning = true;
      FABRICINST.selection = false;
      FABRICINST.lastPosX = eventData.clientX;
      FABRICINST.lastPosY = eventData.clientY;
    }
  });

  // Pan canvas...
  FABRICINST.on("mouse:move", (opt) => {
    if (this.modules.fabric.isPanning) {
      const eventData = opt.e;
      const vpt = FABRICINST.viewportTransform;
      const maxPanX = maxPanDist.x ?? 1000;
      const maxPanY = maxPanDist.y ?? 1000;
      let panX = eventData.clientX - FABRICINST.lastPosX;
      let panY = eventData.clientY - FABRICINST.lastPosY;

      // Limit panning to left/right...
      if (vpt[4] + panX > maxPanX) panX = maxPanX - vpt[4];
      else if (vpt[4] + panX < -maxPanX) panX = -maxPanX - vpt[4];

      // Limit panning to top/bottom...
      if (vpt[5] + panY > maxPanY) panY = maxPanY - vpt[5];
      else if (vpt[5] + panY < -maxPanY) panY = -maxPanY - vpt[5];

      // Apply panning...
      vpt[4] += panX;
      vpt[5] += panY;
      FABRICINST.requestRenderAll();

      // Update last pos. (in order to calculate next pan step)...
      FABRICINST.lastPosX = eventData.clientX;
      FABRICINST.lastPosY = eventData.clientY;
    }
  });

  // Disable panning...
  FABRICINST.on("mouse:up", () => {
    this.modules.fabric.isPanning = false;
    if (this.state.mode === "panning") return; // ← stop, if in manual panning mode...
    FABRICINST.selection = true;
    FABRICINST.getObjects().forEach((item) => {
      item.selectable = true;
      item.setCoords();
    });
  });

  // Render lines to indicate edges of canvas...
  // if (render_canvasEdgeMarkers) {
  //   const lineConfig = { stroke: "black", strokeWidth: 2, selectable: false, strokeDashArray: [10, 10] };
  //   var verticalEdge_left = new fabric.Line([-2450, -2500, -2450, 5495], lineConfig);
  //   var verticalEdge_right = new fabric.Line([7585, -2500, 7585, 5495], lineConfig);
  //   var horizontalEdge_top = new fabric.Line([-2500, -2450, 7650, -2450], lineConfig);
  //   var horizontalEdge_bottom = new fabric.Line([-2500, 5445, 7650, 5445], lineConfig);
  //   FABRICINST.add(verticalEdge_left);
  //   FABRICINST.add(verticalEdge_right);
  //   FABRICINST.add(horizontalEdge_top);
  //   FABRICINST.add(horizontalEdge_bottom);
  // }

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
