// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { v4 as uuidv4 } from "uuid";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["add-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_addBtn_click() {
  // Setup...
  const logArgs = { eventName: "eh_addBtn_click", inline: true };
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  //////////////////////////////////////
  // New assemblage DB entry creation //
  //////////////////////////////////////

  // Init. request...
  const request = new XMLHttpRequest();
  const data = new FormData();
  data.append("assemblageID", uuidv4());
  data.append("accessCode", "1234");
  request.open("POST", "api.assemblage.create", true);

  // Response hdl...
  request.onreadystatechange = () => {
    // Check response...
    const { readyState, status } = request;
    if (readyState === 4 && status === 200) console.log("New assemblage created.");
    if (request.status !== 200) console.log("Assemblage creation failed.");
  };

  // Send request...
  request.send(data);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
