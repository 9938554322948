// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`slide counter max: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_slideCounterMax(CHANGES) {
  if (!("slideCounterMax" in CHANGES)) return;

  // Setup...
  const { slideCounterMax } = CHANGES;
  const { slideCounterMaxDisplay } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(slideCounterMax), "default", { inline: true });

  // Updt. slide counter max display...
  slideCounterMaxDisplay.textContent = slideCounterMax;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
