// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_successfulLoginAttempts", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`successful login attempts: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_successfulLoginAttempts(CHANGES) {
  if (!("successfulLoginAttempts" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { successfulLoginAttempts } = CHANGES;
  const { loginFormState, registerFormState } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(successfulLoginAttempts), "default", logArgs);

  // Enable back-btns & close-btn after 1st successful login attempt...
  if (successfulLoginAttempts >= 1) {
    this.setState({
      loginFormState: { ...loginFormState, backBtn_is_hidden: false },
      registerFormState: { ...registerFormState, backBtn_is_hidden: false },
      closeBtnHidden: false,
    });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
