// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`continue-or-join-CTA state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_continueOrJoinCTAState(CHANGES) {
  if (!("continueOrJoinCTAState" in CHANGES)) return;

  // Setup...
  const { continueOrJoinCTAState } = CHANGES;
  const { continueOrJoinCTA } = this.ref;
  const logArgs = { eventName: "stChH_continueOrJoinCTAState", inline: true };
  this.logger("state-change", loggerMsgs.stateChange(continueOrJoinCTAState), "default", logArgs);

  // Execute nested is_hidden state-change...
  if ("is_hidden" in continueOrJoinCTAState) {
    const { is_hidden } = continueOrJoinCTAState;
    continueOrJoinCTA.setAttribute("data-is-hidden", is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
