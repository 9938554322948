// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { fabric } from "fabric";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_bgGridToggleBtn_click from "../eventHandlers/eh_bgGridToggleBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_fabric_bgGrid", inline: true };
const loggerMsgs = {
  initStart: ["init. fabric_bgGrid"],
  initSuccess: ["init. fabric_bgGrid", "init. success"],
  noValidDOMEl: ["init. fabric_bgGrid", "no valid DOM element(s) provided"],
  noValidFabricInst: ["init. fabric_canvasPan", "no valid fabric instance provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_fabric_bgGrid(FABRICINST) {
  // Setup...
  const { bgGridToggleBtn } = this.ref;
  const { gridVisible } = this.modules.fabric;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!FABRICINST) return this.cancel_featInit(loggerMsgs.noValidFabricInst);

  // Init. grid background pattern...
  fabric.util.loadImage("/app/assets/img/pattern-grid.png", (img) => {
    const pattern = new fabric.Pattern({ source: img, repeat: "repeat" });
    if (gridVisible) {
      FABRICINST.backgroundColor = pattern;
      FABRICINST.renderAll();
    }

    // Toggle btn. event hdl...
    bgGridToggleBtn.addEventListener("click", eh_bgGridToggleBtn_click.bind(this, FABRICINST, pattern));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
