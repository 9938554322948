// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { v4 as uuidv4 } from "uuid";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_registerBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["register-btn was clicked."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_registerBtn_click() {
  // Setup...
  const { postRegisterCTAState, loginURL } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  ///////////////////////////
  // New user registration //
  ///////////////////////////

  // Create new user ID + get assemblage ID from login-URL...
  const newUserID = uuidv4();
  const assemblageID = loginURL.split("/").pop(); // ← get last URL segment

  // Register new user to related assemblage DB entry...
  await this.api.register_user({ newUserID, assemblageID });

  ///////////////////////////////
  // Post-registration actions //
  ///////////////////////////////

  // - Show access code...
  // - Set view to »post-register«...
  this.setState({
    postRegisterCTAState: { ...postRegisterCTAState, show_accessCode: true },
    view: "post-register",
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
