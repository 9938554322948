// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_openSlotsDisplayState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`open-slots-display state`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_openSlotsDisplayState(CHANGES) {
  if (!("openSlotsDisplayState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { openSlotsDisplayState } = CHANGES;
  const { openSlotsNumber } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(openSlotsDisplayState), "default", { ...logArgs, inline: false });

  // Execute nested openSlots state-change...
  if ("openSlots" in openSlotsDisplayState) {
    const { openSlots } = openSlotsDisplayState;
    openSlotsNumber.textContent = openSlots;
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
