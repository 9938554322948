// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_previewJoinBtn_click from "../eventHandlers/eh_previewJoinBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. previews"],
  initSuccess: ["init. previews", "init. success"],
  noValidDOMEl: ["init. previews", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_previews() {
  // Setup...
  const { previews } = this.ref;
  const logArgs = { eventName: "init_previews", inline: true };
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard: Stop, if not preview elements available...
  if (previews === undefined || previews.every((el) => !this.validate_refEl(el)))
    return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Set init. previews-state...
  const assemblagesData = await this.fetch_assemblages();
  const previewsStates = [...assemblagesData];
  this.setState({ previewsStates });

  // Listen to click events on preview-join-btns...
  previews.forEach((previewEl, i) => {
    const joinBtn = previewEl.querySelector("[data-ref='joinBtn']");
    if (!joinBtn) return;
    joinBtn.addEventListener("click", eh_previewJoinBtn_click.bind(this, previewEl));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
