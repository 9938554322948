// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["cancel-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_cancelBtn_click() {
  // Setup...
  const { selectedTile } = this.state;
  const { el: selectedTileEl } = selectedTile;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  if (selectedTileEl) {
    // If selected tile had no color before editing, clear its color...
    if (selectedTileEl.dataset.hasColor === "false") selectedTileEl.style.setProperty("--color", "transparent");

    // De-init free-draw canvas...
    const canvasEl = selectedTileEl.querySelector("canvas");
    const { canvasInst } = this.state;
    if (canvasInst && canvasInst != "null" && canvasEl) this.deinit_canvas(canvasEl);
  }

  // Close editing dialog + deselect tile + reset selected color...
  this.setState({
    editingDialogState: { mode: "inactive" },
    selectedTile: { el: "null" },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
