// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_finishBtn_click from "../eventHandlers/eh_finishBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_finishBtn", inline: true };
const loggerMsgs = {
  initStart: ["init. finishBtn"],
  initSuccess: ["init. finishBtn", "init. success"],
  noValidDOMEl: ["init. finishBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_finishBtn() {
  // Setup...
  const { finishBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(finishBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on finishBtn...
  finishBtn.addEventListener("click", eh_finishBtn_click.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
