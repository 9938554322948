// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_confirmContinueBtn_click from "../eventHandlers/eh_confirmContinueBtn_click.js";
import eh_denyContinueBtn_click from "../eventHandlers/eh_denyContinueBtn_click.js";
import eh_backToContinueOrJoinBtn_click from "../eventHandlers/eh_backToContinueOrJoinBtn_click.js";
import eh_loginBtn_click from "../eventHandlers/eh_loginBtn_click.js";
import eh_loginInput_change from "../eventHandlers/eh_loginInput_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_loginForm", inline: true };
const loggerMsgs = {
  initStart: ["init. loginForm"],
  initSuccess: ["init. loginForm", "init. success"],
  noValidDOMEl: ["init. loginForm", "no valid DOM elements provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_loginForm() {
  // Setup...
  const { confirmContinueBtn, denyContinueBtn, backToContinueOrJoinBtns, loginBtn, loginInput } = this.ref;
  const refs = [confirmContinueBtn, denyContinueBtn, ...backToContinueOrJoinBtns, loginBtn, loginInput];
  const { backBtns_are_initHidden } = this.options;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (refs === undefined || refs.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on buttons...
  confirmContinueBtn?.addEventListener("click", eh_confirmContinueBtn_click.bind(this));
  denyContinueBtn?.addEventListener("click", eh_denyContinueBtn_click.bind(this));
  backToContinueOrJoinBtns?.forEach((btn) => btn.addEventListener("click", eh_backToContinueOrJoinBtn_click.bind(this)));
  loginBtn?.addEventListener("click", eh_loginBtn_click.bind(this));

  // Listen to input events on login input...
  loginInput?.addEventListener("input", eh_loginInput_change.bind(this));

  // Set init. login-form state...
  const loginFormState = { is_hidden: true, backBtn_is_hidden: backBtns_are_initHidden };
  this.setState({ loginFormState });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
