// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`selectedColor: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedColor(CHANGES) {
  if (!("selectedColor" in CHANGES)) return;

  // Setup...
  const { selectedColor } = CHANGES;
  const { selectedTile, mode } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(selectedColor), "default", { inline: true });

  // If tile is selected, updt. its color (unless comp. is in »draw« mode)...
  if (mode !== "draw") {
    if (selectedTile?.el && selectedTile?.el !== "null") selectedTile.el.style.setProperty("--color", selectedColor);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
