// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function updt_canvasMousePos(EVENTDATA) {
  // Calc. mouse pos. relative to canvas...
  const canvasRect = EVENTDATA.target.getBoundingClientRect();
  const { clientX, clientY } = EVENTDATA;
  const { left, top } = canvasRect;
  const x = clientX - left;
  const y = clientY - top;
  return { x, y };
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const defaultConfig = {
  lineWidth: 10,
  lineCap: "round",
  strokeStyle: "#000",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function draw_onCanvas(EVENTDATA, CANVASEL, CONFIG = defaultConfig) {
  // Stop, if left mouse button is not being pressed...
  const { canvasMousePos = { x: 0, y: 0 }, selectedColor } = this.state;
  if (EVENTDATA.buttons !== 1) return;

  // Setup...
  const config = { ...defaultConfig, ...CONFIG };
  const { lineWidth, lineCap, strokeStyle } = config;

  // Draw...
  var ctx = CANVASEL.getContext("2d");
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.lineCap = lineCap;
  ctx.strokeStyle = selectedColor || strokeStyle;
  ctx.moveTo(canvasMousePos.x, canvasMousePos.y); // from

  // Updt. canvas mouse pos...
  const newCanvasMousePos = updt_canvasMousePos(EVENTDATA);
  this.setState({ canvasMousePos: newCanvasMousePos });

  // Draw...
  ctx.lineTo(canvasMousePos.x, canvasMousePos.y); // to
  ctx.stroke();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
