// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function fetch_participantCount(PAGEID) {
  if (!PAGEID) return console.warn("fetch_participantCount(): No page ID provided.");
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("GET", "/api.assemblage.participantCount/" + PAGEID, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const participantCount = JSON.parse(req.responseText).participantCount;
        if (!participantCount) return console.warn("fetch_participantCount(): No participant count found.");
        resolve(participantCount);
      }
    };
    req.send();
  });
}

function init_openSlotsDisplayUpdt() {
  return setInterval(async () => {
    const pageID = this.state.route?.pageID || window.location.pathname.split("/").pop();
    if (!pageID) return console.warn("init_openSlotsDisplay(): No page ID found.");
    const participantCount = await fetch_participantCount(pageID);
    if (!participantCount) return console.warn("init_openSlotsDisplay(): No participant count found.");
    const openSlotsDisplayState_old = this.state.openSlotsDisplayState;
    const openSlotsDisplayState_new = { ...openSlotsDisplayState_old, participantCount };
    this.setState({ openSlotsDisplayState: openSlotsDisplayState_new });
  }, 10000);
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. openSlotsDisplay"],
  initSuccess: ["init. openSlotsDisplay", "init. success"],
  noValidDOMEl: ["init. openSlotsDisplay", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_openSlotsDisplay() {
  // Setup...
  const { openSlotsDisplay } = this.ref;
  const logArgs = { eventName: "init_openSlotsDisplay", inline: true };
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (openSlotsDisplay === undefined || !this.validate_refEl(openSlotsDisplay))
    return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Get init. open slots count (for curr. page ID) from DB...
  const pageID = this.state.route?.pageID || window.location.pathname.split("/").pop();
  const participantCount = await fetch_participantCount(pageID);
  if (participantCount) {
    const oldState = this.state.openSlotsDisplayState;
    this.setState({ openSlotsDisplayState: { ...oldState, participantCount } });
  }

  // Every 10 seconds, updt. open slots count...
  const openSlotsUpdtInterval = init_openSlotsDisplayUpdt.bind(this)();
  this.setState({ openSlotsUpdtIntervalID: openSlotsUpdtInterval });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
