// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function delete_userSession(SESSIONDATA) {
  if (!SESSIONDATA || SESSIONDATA == undefined)
    return console.warn("delete_userSession(SESSIONDATA): No user session data provided.");
  localStorage.removeItem("UDKAss_userSession");
  const request = new XMLHttpRequest();
  const data = new FormData();
  data.append("sessionID", SESSIONDATA.id);
  request.open("POST", "/api.delete.activeUserSession", true);
  request.onreadystatechange = () => {
    if (request.readyState === 4 && request.status === 200) console.log("%cUser session deleted.", consoleStyles.success);
    if (request.status !== 200) console.log("%cUser session deletion failed.", consoleStyles.error);
  };
  request.send(data);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
