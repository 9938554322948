// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * @description Function to handle changes to the mode state.
 *              The mode state may be changed to one of the following values:
 *
 *              - "init"     → the component’s init. functions are about to be called
 *
 *              - "ready"    → all component’s init. functions have been called, but it is inactive
 *                           → interactions are disabled (no pointer events on entire component el.)
 *                           → scrolling is disabled (scroll instance is stopped)
 *
 *              - "active"   → the component is active;
 *                           → interactions are enabled
 *
 *              - "inactive" → interactions are disabled
 *
 *              The mode state changes at the occurence of the following events:
 *
 *              - "EVENTBUSCALL" → "inactive" when ...,
 *                               → "active" when ...,
 *
 * @param {Object} CHANGES - Object containing the changes to the mode state.
 **/

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`mode: ${CHANGE}`],
  error_noStateChange: () => ["No change to mode state provided."],
  error_invalidStateValue: (VALUE) => [`Invalid state value: ${VALUE}`],
};

const possibleStateValues = ["init", "ready", "active", "inactive", "drawing", "free-drawing", "editing", "panning"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mode(CHANGES) {
  if (!("mode" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error");
  if (!possibleStateValues.includes(CHANGES.mode))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.mode), "error");

  // Setup...
  const { mode } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(mode), "default", { inline: true });

  // Set controls-state...
  this.setState({
    controlsState: {
      drawBtn: {
        is_active: mode !== "editing" && mode !== "free-drawing",
        is_selected: mode === "drawing",
      },
      freeDrawBtn: {
        is_active: mode !== "editing" && mode !== "drawing",
        is_selected: mode === "free-drawing",
      },
      finishBtn: {
        is_active: mode === "drawing",
      },
      deleteBtn: {
        is_active: mode === "editing",
      },
      cancelBtn: {
        is_active: mode === "drawing" || mode === "free-drawing",
      },
      saveBtn: {
        is_active: mode !== "editing" && mode !== "drawing",
      },
      panBtn: {
        is_active: mode !== "editing" && mode !== "free-drawing",
        is_selected: mode === "panning",
      },
    },
  });

  // If in »free-drawing« mode, enable free-drawing on fabric instance canvas...
  const fabricInst = this.modules.fabric.instance;
  if (fabricInst) {
    const { selectedColor } = this.state;
    if (mode === "free-drawing") {
      fabricInst.isDrawingMode = true;
      fabricInst.freeDrawingBrush.width = 5;
      fabricInst.freeDrawingBrush.color = selectedColor;
    } else {
      fabricInst.isDrawingMode = false;
    }
  } else return console.warn("stChH_mode(): no fabric instance found");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
