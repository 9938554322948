// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_canvasDataInDB", inline: true };
const loggerMsgs = {
  eventStart: ["VecDrawTool", "API", "updt_canvasDataInDB"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_canvasDataInDB(
  DATA = {
    canvasData: null,
    assemblageID: null,
  }
) {
  return new Promise((resolve, reject) => {
    if (!DATA || DATA == undefined) reject("updt_canvasDataInDB(DATA): No data provided.");
    if (!DATA.canvasData || DATA.canvasData == undefined) reject("updt_canvasDataInDB(DATA): No canvas data provided.");
    if (!DATA.assemblageID || DATA.assemblageID == undefined) reject("updt_canvasDataInDB(DATA): No assemblage ID provided.");

    // Setup...
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Set up request...
    const req = new XMLHttpRequest();
    const data = new FormData();

    // Prep. req. data...
    data.append("assemblageID", DATA.assemblageID);
    data.append("canvasData", JSON.stringify(DATA.canvasData));

    // Init. + send request...
    req.open("POST", "/api.assemblage.updtFabricCanvasData", true);
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) console.log("%cCanvas data updated.", consoleStyles.success);
      if (req.status !== 200) {
        console.log("%Canvas data updt. failed.", consoleStyles.error);
        reject("updt_canvasDataInDB(DATA): Canvas data update failed.");
      }
      resolve(req.responseText);
    };
    req.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
