// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {};

const rules_routesWithOnlineUsersDisplay = [{ pattern: "/assemblages/:id" }];
const rules_routesWithOpenSlotsDisplay = [{ pattern: "/assemblages/:id" }];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_UserTracker_ready(ARGS) {
  // Setup...
  const { _name } = ARGS;
  const { url } = this.state.route;
  const logArgs = { eventName: "ebh_UserTracker_ready" };
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // If route shows open-slots display, init. open-slots display + set init. openSlotsDisplay state,
  // otherwise stop open-slots display updates + hide open-slots display...
  const hideOpenSlotsDisplay = !this.check_routeMatchesRule(url, rules_routesWithOpenSlotsDisplay);
  if (!hideOpenSlotsDisplay) this.init_openSlotsDisplay();
  else clearInterval(this.state.openSlotsUpdtIntervalID);
  this.setState({ openSlotsDisplayState: { is_hidden: hideOpenSlotsDisplay } });

  // If route shows online-users display, init. online-users display + set init. onlineUsersDisplay state,
  // otherwise stop online-users display updates + hide online-users display...
  const hideOnlineUsersDisplay = !this.check_routeMatchesRule(url, rules_routesWithOnlineUsersDisplay);
  if (!hideOnlineUsersDisplay) this.init_onlineUsersDisplay();
  else clearInterval(this.state.onlineUsersUpdtIntervalID);
  this.setState({ onlineUsersDisplayState: { is_hidden: hideOnlineUsersDisplay } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
