// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. user session updates"],
  initSuccess: ["init. user session updates", "init. success"],
  noCurrentUserSessionFound: ["init. user session updates", "no current user session found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_sessionUpdt() {
  // Setup...
  const logArgs = { eventName: "init_newUserSession", inline: true };
  const { currUserSession } = this.state;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard: Check for current user session...
  if (!currUserSession) return this.logger("init", loggerMsgs.noCurrentUserSessionFound, "warning", { inline: true });

  // Update current user session every 10 seconds...
  const userSessionUpdtInterval = setInterval(() => {
    currUserSession.lastActive = new Date();
    this.api.store_userSession(currUserSession);
  }, 10000);
  this.setState({ userSessionUpdtIntervalID: userSessionUpdtInterval }); // ← store interval ID in state for local use

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
