// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.zoomCanvas", inline: true };
const loggerMsgs = {
  eventStart: ["VecDrawTool", "API", "zoomCanvas"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function zoomCanvas(DIRECTION = "out") {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  if (!fabricInst) return console.warn("eh_fabricCanvas_objMoving(): no fabric instance found");

  // Zoom fabric canvas according to passed direction...
  const maxZoomOut = 0.5;
  const maxZoomIn = 1;
  const center = fabricInst.getCenter();
  const centerPoint = new fabric.Point(center.left, center.top);
  const zoomFactor = DIRECTION === "out" ? -0.1 : 0.1;
  if ((DIRECTION === "out" && fabricInst.getZoom() <= maxZoomOut) || (DIRECTION === "in" && fabricInst.getZoom() >= maxZoomIn))
    return;
  fabricInst.zoomToPoint(centerPoint, fabricInst.getZoom() + zoomFactor);
  fabricInst.renderAll();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
