// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_hidden(CHANGES) {
  if (!("hidden" in CHANGES)) return;

  // Setup...
  const { hidden } = CHANGES;
  const { openModalBtn } = this.ref;
  const { ctaState_dontShowAgain } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(hidden), "default", { inline: true });

  // Toggle comp. visibility...
  this.element.setAttribute("data-is-hidden", hidden);

  // Toggle open-modal-btn visibility...
  openModalBtn.setAttribute("data-is-hidden", !hidden);

  // If visible, hide »Dont show again«-CTA...
  if (!ctaState_dontShowAgain?.hidden) this.setState({ ctaState_dontShowAgain: { hidden: true } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
