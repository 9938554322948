// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function fetch_activeUserSessions(PAGEID) {
  if (!PAGEID) return console.warn("fetch_activeUserSessions(): No page ID provided.");
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("GET", "/api.get.activeUserSessions/" + PAGEID, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const activeUserSessions = JSON.parse(req.responseText);
        if (!activeUserSessions) return console.warn("fetch_activeUserSessions(): No active user sessions found.");
        resolve(activeUserSessions);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.warn("fetch_activeUserSessions(): Request failed.");
        reject();
      }
    };
    req.send();
  });
}

function init_onlineUsersDisplayUpdt() {
  return setInterval(async () => {
    const pageID = this.state.route?.pageID || window.location.pathname.split("/").pop();
    if (!pageID) return console.warn("init_onlineUsersDisplay(): No page ID found.");
    const activeUserSessions = await fetch_activeUserSessions(pageID);
    if (!activeUserSessions) return console.warn("init_onlineUsersDisplay(): No active user sessions found.");
    const onlineUsersDisplayState_old = this.state.onlineUsersDisplayState;
    const onlineUsersDisplayState_new = { ...onlineUsersDisplayState_old, activeUserSessions };
    this.setState({ onlineUsersDisplayState: onlineUsersDisplayState_new });
  }, 10000);
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. onlineUsersDisplay"],
  initSuccess: ["init. onlineUsersDisplay", "init. success"],
  noValidDOMEl: ["init. onlineUsersDisplay", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_onlineUsersDisplay() {
  // Setup...
  const { onlineUsersDisplay } = this.ref;
  const logArgs = { eventName: "init_onlineUsersDisplay", inline: true };
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (onlineUsersDisplay === undefined || !this.validate_refEl(onlineUsersDisplay))
    return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Get init. active user count (for curr. page ID) from DB...
  const pageID = this.state.route?.pageID || window.location.pathname.split("/").pop();
  const activeUserSessions = await fetch_activeUserSessions(pageID);
  if (activeUserSessions) {
    const oldState = this.state.onlineUsersDisplayState;
    this.setState({ onlineUsersDisplayState: { ...oldState, activeUserSessions } });
  }

  // Every 10 seconds, updt. active user count...
  const onlineUsersUpdtInterval = init_onlineUsersDisplayUpdt.bind(this)();
  this.setState({ onlineUsersUpdtIntervalID: onlineUsersUpdtInterval });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
