// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";
import updt_tooltip from "./util/updt_tooltip.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_cancelBtn from "./init/init_cancelBtn.js";
import init_tooltip from "./init/init_tooltip.js";
import init_fabric from "./init/init_fabric.js";
import init_fabric_canvasPan from "./init/init_fabric_canvasPan.js";
import init_fabric_bgGrid from "./init/init_fabric_bgGrid.js";
import init_drawBtn from "./init/init_drawBtn.js";
import init_freeDrawBtn from "./init/init_freeDrawBtn.js";
import init_finishBtn from "./init/init_finishBtn.js";
import init_deleteBtn from "./init/init_deleteBtn.js";
import init_saveBtn from "./init/init_saveBtn.js";
import init_undoBtn from "./init/init_undoBtn.js";
import init_colorPicker from "./init/init_colorPicker.js";
import init_zoomBtns from "./init/init_zoomBtns.js";
import init_panBtn from "./init/init_panBtn.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_lastChanged from "./stateChangeHandlers/stChH_lastChanged.js";
import stChH_lastCanvasData from "./stateChangeHandlers/stChH_lastCanvasData.js";
import stChH_lastColorSelect from "./stateChangeHandlers/stChH_lastColorSelect.js";
import stChH_lastSaved from "./stateChangeHandlers/stChH_lastSaved.js";
import stChH_drawingSession from "./stateChangeHandlers/stChH_drawingSession.js";
import stChH_controlsState from "./stateChangeHandlers/stChH_controlsState.js";
import stChH_selectedColor from "./stateChangeHandlers/stChH_selectedColor.js";

import updt_canvasDataInDB from "./api/updt_canvasDataInDB.js";
import get_canvasDataFromDB from "./api/get_canvasDataFromDB.js";
import log_canvasChange from "./api/log_canvasChange.js";
import cancel_drawing from "./api/cancel_drawing.js";
import save_canvasSnapshot from "./api/save_canvasSnapshot.js";
import zoomCanvas from "./api/zoomCanvas.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = { optionkey: { foo: "bar" } };
const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class VecDrawTool extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      drawBtn: null,
      freeDrawBtn: null,
      finishBtn: null,
      deleteBtn: null,
      cancelBtn: null,
      saveBtn: null,
      undoBtn: null,
      zoomOutBtn: null,
      zoomInBtn: null,
      panBtn: null,
      bgGridToggleBtn: null,
      canvas: null,
      colorPicker: null,
      //////
      tooltip_colorPicker: null,
      tooltip_zoomOutBtn: null,
      tooltip_zoomInBtn: null,
      tooltip_panBtn: null,
      tooltip_drawBtn: null,
      tooltip_freeDrawBtn: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "VecDrawTool",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      pageID: "defaultValue",
      //////
      run_withLogs: false,
      logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.updt_tooltip = updt_tooltip.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_cancelBtn = init_cancelBtn.bind(this);
    this.init_fabric_bgGrid = init_fabric_bgGrid.bind(this);
    this.init_tooltip = init_tooltip.bind(this);
    this.init_panBtn = init_panBtn.bind(this);
    this.init_fabric_canvasPan = init_fabric_canvasPan.bind(this);
    this.init_zoomBtns = init_zoomBtns.bind(this);
    this.init_undoBtn = init_undoBtn.bind(this);
    this.init_freeDrawBtn = init_freeDrawBtn.bind(this);
    this.init_saveBtn = init_saveBtn.bind(this);
    this.init_colorPicker = init_colorPicker.bind(this);
    this.init_deleteBtn = init_deleteBtn.bind(this);
    this.init_finishBtn = init_finishBtn.bind(this);
    this.init_fabric = init_fabric.bind(this);
    this.init_drawBtn = init_drawBtn.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      fabric: {
        instance: null,
        gridVisible: false,
      },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      cancel_drawing: cancel_drawing.bind(this),
      save_canvasSnapshot: save_canvasSnapshot.bind(this),
      zoomCanvas: zoomCanvas.bind(this),
      log_canvasChange: log_canvasChange.bind(this),
      get_canvasDataFromDB: get_canvasDataFromDB.bind(this),
      updt_canvasDataInDB: updt_canvasDataInDB.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_lastColorSelect = stChH_lastColorSelect.bind(this);
    this.stChL_lastCanvasData = stChH_lastCanvasData.bind(this);
    this.stChL_lastChanged = stChH_lastChanged.bind(this);
    this.stChL_lastSaved = stChH_lastSaved.bind(this);
    this.stChL_selectedColor = stChH_selectedColor.bind(this);
    this.stChL_controlsState = stChH_controlsState.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_drawingSession = stChH_drawingSession.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("VecDrawTool.api.cancel_drawing", this.api.cancel_drawing);
    eventbus.off("VecDrawTool.api.save_canvasSnapshot", this.api.save_canvasSnapshot);
    eventbus.off("VecDrawTool.api.zoomCanvas", this.api.zoomCanvas);
    eventbus.off("VecDrawTool.api.log_canvasChange", this.api.log_canvasChange);
    eventbus.off("VecDrawTool.api.get_canvasDataFromDB", this.api.get_canvasDataFromDB);
    eventbus.off("VecDrawTool.api.updt_canvasDataInDB", this.api.updt_canvasDataInDB);
    eventbus.off("VecDrawTool.api.hideComp", this.api.hideComp);
    eventbus.off("VecDrawTool.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async init() {
    this.setState({ mode: "init" });
    await this.init_fabric({ restoreOnInit: true });
    this.init_zoomBtns();
    this.init_panBtn();
    this.init_undoBtn();
    this.init_cancelBtn();
    this.init_freeDrawBtn();
    this.init_saveBtn();
    this.init_colorPicker();
    this.init_deleteBtn();
    this.init_finishBtn();
    this.init_drawBtn();
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({
      lastCanvasData: [],
      is_mobile: window.innerWidth < 640,
      // selectedColor: "#000",
      controlsState: {
        drawBtn: { is_active: true, is_selected: false },
        freeDrawBtn: { is_active: true, is_selected: false },
        finishBtn: { is_active: false },
        deleteBtn: { is_active: false },
        cancelBtn: { is_active: false },
        saveBtn: { is_active: true },
        panBtn: { is_active: true, is_selected: false },
      },
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("VecDrawTool.api.cancel_drawing", this.api.cancel_drawing);
    eventbus.on("VecDrawTool.api.save_canvasSnapshot", this.api.save_canvasSnapshot);
    eventbus.on("VecDrawTool.api.zoomCanvas", this.api.zoomCanvas);
    eventbus.on("VecDrawTool.api.log_canvasChange", this.api.log_canvasChange);
    eventbus.on("VecDrawTool.api.get_canvasDataFromDB", this.api.get_canvasDataFromDB);
    eventbus.on("VecDrawTool.api.updt_canvasDataInDB", this.api.updt_canvasDataInDB);
    eventbus.on("VecDrawTool.api.hideComp", this.api.hideComp);
    eventbus.on("VecDrawTool.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("lastCanvasData" in CHANGES) this.stChL_lastCanvasData(CHANGES);
    if ("lastChanged" in CHANGES) this.stChL_lastChanged(CHANGES);
    if ("lastSaved" in CHANGES) this.stChL_lastSaved(CHANGES);
    if ("lastColorSelect" in CHANGES) this.stChL_lastColorSelect(CHANGES);
    if ("selectedColor" in CHANGES) this.stChL_selectedColor(CHANGES);
    if ("controlsState" in CHANGES) this.stChL_controlsState(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
    if ("drawingSession" in CHANGES) this.stChL_drawingSession(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default VecDrawTool;
