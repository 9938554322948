// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_shapePicker_click from "../eventHandlers/eh_shapePicker_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. shapePicker"],
  initSuccess: ["init. shapePicker", "init. success"],
  noValidDOMEl: ["init. shapePicker", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_shapePicker() {
  // Setup...
  const { shapePicker, tooltip_shapePicker } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEl(shapePicker)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on shape picker...
  shapePicker.addEventListener("click", eh_shapePicker_click.bind(this));

  // Init. tooltip...
  if (this.validate_refEl(tooltip_shapePicker)) {
    shapePicker.addEventListener("mouseenter", () => this.updt_tooltip(shapePicker, tooltip_shapePicker));
    shapePicker.addEventListener("mouseleave", () => tooltip_shapePicker.setAttribute("data-is-hidden", "true"));
  }

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
