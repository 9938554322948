// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_hideTransition_end from "../eventHandlers/eh_hideTransition_end.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. hideTransition"],
  initSuccess: ["init. hideTransition", "init. success"],
  noValidDOMEl: ["init. hideTransition", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_hideTransition() {
  // Setup...
  const logArgs = { eventName: "init_hideTransition", inline: true };
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  // ...

  // Listen to end of opacity transition...
  this.element.addEventListener("transitionend", (EVENTDATA) => {
    if (EVENTDATA.target !== this.element) return; // ← stop, if event target is not the comp. main el. (prevent bubbling up from child el.)
    if (EVENTDATA.propertyName === "opacity") eh_hideTransition_end.call(this, EVENTDATA);
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
