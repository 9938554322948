// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function save_tile() {
  // Setup...
  const { selectedTile, selectedColor, selectedShape } = this.state;
  if (!selectedTile || !selectedTile.el) return console.warn("save_tile(): No selected tile found in state.");
  if (!selectedColor) return console.warn("save_tile(): No selected color found in state.");
  const { el: tileEl } = selectedTile;

  // Create updt. request...
  const request = new XMLHttpRequest();
  const data = new FormData();

  // Prep. tile data...
  const { col, row } = tileEl.dataset;
  data.append("col", col);
  data.append("row", row);

  // Save canvas data (if available)...
  const canvasEl = tileEl.querySelector("canvas");
  const canvasInst = this.state.canvasInst;
  const has_activeCanvas = canvasEl && canvasInst && canvasInst !== "null";
  if (has_activeCanvas) data.append("canvasImg", canvasEl.toDataURL("image/png"));
  // Save color + shape...
  else {
    data.append("color", selectedColor);
    data.append("shape", selectedShape);
  }

  // Init. updt. request...
  request.open("POST", "api.coulage.saveTile", true);

  // Set tile el. load spinner active...
  const loadSpinner = tileEl.querySelector("[data-ref='loadSpinner']");
  if (loadSpinner) loadSpinner.setAttribute("data-is-active", true);

  // Response hdl...
  request.onreadystatechange = () => {
    if (request.readyState === 4 && request.status === 200) {
      // Close editing dialog + deselect tile + set load spinner inactive...
      this.setState({
        editingDialogState: { mode: "inactive" },
        selectedTile: { el: "null" },
        selectedShape: "rectangle",
      });
      loadSpinner.setAttribute("data-is-active", false);
    }
  };

  // Send updt. request...
  request.send(data);

  // De-init free-draw canvas...
  if (has_activeCanvas) this.deinit_canvas(canvasEl, { clearCanvas: false });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
