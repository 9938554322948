// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`login-form state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_loginFormState(CHANGES) {
  if (!("loginFormState" in CHANGES)) return;

  // Setup...
  const { loginFormState } = CHANGES;
  const { loginForm } = this.ref;
  const logArgs = { eventName: "stChH_loginFormState" };
  this.logger("state-change", loggerMsgs.stateChange(loginFormState), "default", logArgs);

  // Execute nested is_hidden state-change...
  if ("is_hidden" in loginFormState) {
    const { is_hidden } = loginFormState;
    loginForm.setAttribute("data-is-hidden", is_hidden);
  }

  // Execute nested backBtn_is_hidden state-change...
  if ("backBtn_is_hidden" in loginFormState) {
    const { backBtn_is_hidden } = loginFormState;
    const backBtn = loginForm.querySelector("[g-ref='backToContinueOrJoinBtns']");
    if (backBtn) backBtn.setAttribute("data-is-hidden", backBtn_is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
