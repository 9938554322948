// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_controlsState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`controls state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_controlsState(CHANGES) {
  if (!("controlsState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { controlsState } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(controlsState), "default", { ...logArgs, inline: false });

  // Execute nested drawBtn state changes...
  if ("drawBtn" in controlsState) {
    const { drawBtn } = this.ref;
    const { drawBtn: drawBtnState } = controlsState;
    const { is_active, is_selected } = drawBtnState;
    drawBtn.setAttribute("data-is-active", is_active);
    drawBtn.setAttribute("data-is-selected", is_selected);
  }

  // Execute nested freeDrawBtn state changes...
  if ("freeDrawBtn" in controlsState) {
    const { freeDrawBtn } = this.ref;
    const { freeDrawBtn: freeDrawBtnState } = controlsState;
    const { is_active, is_selected } = freeDrawBtnState;
    freeDrawBtn.setAttribute("data-is-active", is_active);
    freeDrawBtn.setAttribute("data-is-selected", is_selected);
  }

  // Execute nested finishBtn state changes...
  if ("finishBtn" in controlsState) {
    const { finishBtn } = this.ref;
    const { finishBtn: finishBtnState } = controlsState;
    const { is_active } = finishBtnState;
    finishBtn.setAttribute("data-is-active", is_active);
  }

  // Execute nested deleteBtn state changes...
  if ("deleteBtn" in controlsState) {
    const { deleteBtn } = this.ref;
    const { deleteBtn: deleteBtnState } = controlsState;
    const { is_active } = deleteBtnState;
    deleteBtn.setAttribute("data-is-active", is_active);
  }

  // Execute nested cancelBtn state changes...
  if ("cancelBtn" in controlsState) {
    const { cancelBtn } = this.ref;
    const { cancelBtn: cancelBtnState } = controlsState;
    const { is_active } = cancelBtnState;
    cancelBtn.setAttribute("data-is-active", is_active);
  }

  // Execute nested saveBtn state changes...
  if ("saveBtn" in controlsState) {
    const { saveBtn } = this.ref;
    const { saveBtn: saveBtnState } = controlsState;
    const { is_active } = saveBtnState;
    saveBtn.setAttribute("data-is-active", is_active);
  }

  // Execute nested panBtn state changes...
  if ("panBtn" in controlsState) {
    const { panBtn } = this.ref;
    const { panBtn: panBtnState } = controlsState;
    const { is_active, is_selected } = panBtnState;
    panBtn.setAttribute("data-is-active", is_active);
    panBtn.setAttribute("data-is-selected", is_selected);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
