// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`mode: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mode(CHANGES) {
  if (!("mode" in CHANGES)) return;

  // Setup...
  const { mode } = CHANGES;
  const { drawBtn } = this.ref;
  const { selectedTile } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(mode), "default", { inline: true });

  /////////////////
  // »Draw« mode //
  /////////////////

  if (mode === "draw") {
    // Set draw btn. to active...
    drawBtn.setAttribute("data-is-active", "true");

    // Init free-draw canvas...
    const tileEl = selectedTile?.el;
    if (!tileEl) return console.warn("Draw mode: no valid selected tile el. found");
    const canvasEl = tileEl.querySelector("canvas");
    if (!canvasEl) return console.warn("Draw mode: no valid canvas el. found");
    this.init_canvas(canvasEl);
  } else {
    drawBtn.setAttribute("data-is-active", "false");
  }

  /////////////////
  // »Idle« mode //
  /////////////////

  if (mode === "idle") {
    // ...
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
