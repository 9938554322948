// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_loadSpinnerHidden", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`load spinner hidden: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_loadSpinnerHidden(CHANGES) {
  if (!("loadSpinnerHidden" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.loadSpinnerHidden))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.loadSpinnerHidden), "error", logArgs);

  // Setup...
  const { loadSpinnerHidden } = CHANGES;
  const { loadSpinner } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(loadSpinnerHidden), "default", logArgs);

  // Toggle load spinner visibility...
  loadSpinner.setAttribute("data-is-hidden", loadSpinnerHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
