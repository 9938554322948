// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_openSlotsDisplay", inline: true };
const loggerMsgs = {
  initStart: ["init. openSlotsDisplay"],
  initSuccess: ["init. openSlotsDisplay", "init. success"],
  noValidDOMEl: ["init. openSlotsDisplay", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_openSlotsDisplay() {
  // Setup...
  const { openSlotsNumber } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(openSlotsNumber)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Set init. open-slots-display state...
  const openSlotsDisplayState = { openSlots: 20 };
  this.setState({ openSlotsDisplayState });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
