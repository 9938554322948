// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_postRegisterCTAState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`post-register-CTA state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_postRegisterCTAState(CHANGES) {
  if (!("postRegisterCTAState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { postRegisterCTAState } = CHANGES;
  const { postRegisterCTA, accessCodeDisplay } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(postRegisterCTAState), "default", { ...logArgs, inline: false });

  // Execute nested is_hidden state-change...
  if ("is_hidden" in postRegisterCTAState) {
    const { is_hidden } = postRegisterCTAState;
    postRegisterCTA.setAttribute("data-is-hidden", is_hidden);
  }

  // Execute nested show_accessCode state-change...
  if ("show_accessCode" in postRegisterCTAState) {
    const { show_accessCode } = postRegisterCTAState;
    accessCodeDisplay.textContent = show_accessCode ? this.state.accessCode : "----";
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
