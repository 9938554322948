// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_zoomBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["zoom-out btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_zoomBtn_click(BUTTONEL, EVENTDATA) {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  if (!fabricInst) return console.warn("eh_fabricCanvas_objMoving(): no fabric instance found");

  // Zoom fabric canvas in/out...
  const direction = BUTTONEL === this.ref.zoomOutBtn ? "out" : "in";
  this.api.zoomCanvas(direction);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
