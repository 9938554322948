// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["mouse moved on canvas"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_canvas_mouseMove(CANVASEL, EVENTDATA) {
  // Stop, if left mouse button is not being pressed...
  if (EVENTDATA.buttons !== 1) return;

  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Draw on canvas...
  this.draw_onCanvas(EVENTDATA, CANVASEL);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
