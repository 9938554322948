// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["login-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_loginBtn_click() {
  // Setup...
  const { loginInput, notif_wrongAccessCode } = this.ref;
  const { loginURL, accessCode, loginAttempts, successfulLoginAttempts } = this.state;
  const logArgs = { eventName: "eh_loginBtn_click", inline: true };
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // - Show load spinner...
  // - Switch to loading mode...
  // - Increment login-attempt count...
  this.setState({
    loadSpinnerHidden: false,
    mode: "loading",
    loginAttempts: loginAttempts + 1,
  });

  // (fake delay)
  setTimeout(async () => {
    // Check access code against user input...
    if (loginInput.value !== accessCode) {
      this.logger("event", ["entered access code is incorrect"], "error", logArgs);
      notif_wrongAccessCode.setAttribute("data-is-hidden", "false");
      this.setState({ loadSpinnerHidden: true });
      return;
    }

    // Check, if already on login route...
    if (window.location.pathname === loginURL) {
      this.logger("warning", ["already on login route"], "warning", logArgs);
      this.setState({
        hidden: true,
        mode: "ready",
        successfulLoginAttempts: successfulLoginAttempts + 1,
      });
      return;
    }

    // Check if valid login URL is set...
    if (loginURL === null || loginURL === "null" || loginURL === "") {
      this.logger("warning", ["no valid login URL set"], "warning", logArgs);
      this.setState({
        hidden: true,
        mode: "ready",
        successfulLoginAttempts: successfulLoginAttempts + 1,
      });
      return;
    }

    // Go to login route via Swup app mod. API...
    const args = { url: loginURL };
    notif_wrongAccessCode.setAttribute("data-is-hidden", "true");
    await eventbus.emit("Swup.api.goToPage", args);
    this.setState({ successfulLoginAttempts: successfulLoginAttempts + 1 });
  }, 1000);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
