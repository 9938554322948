// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_finishBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["finish-btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_finishBtn_click(EVENTDATA) {
  // Setup...
  const { mode } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (mode != "drawing") return console.warn("eh_finishBtn_click(): not in drawing mode");

  /////////////////////////////////////
  // Vector shape drawing conclusion //
  /////////////////////////////////////

  // Setup...
  const fabricInst = this.modules.fabric.instance;
  if (!fabricInst) return console.warn("eh_fabricCanvas_mouseDown(): no fabric instance found");
  const drawingSession = this.state.drawingSession || { points: [] };
  const { selectedColor = "#000" } = this.state || {};

  // Create polygon from points in drawing session...
  const points = drawingSession.points;
  const polygon = new fabric.Polygon(points, {
    fill: selectedColor,
    selectable: true,
    hasControls: true,
    hasBorders: true,
  });

  // Add polygon to canvas...
  fabricInst.add(polygon);

  // Log canvas change...
  this.api.log_canvasChange({ disregardMode: true });

  // Reset drawing session...
  this.setState({ drawingSession: { points: [] } });

  // Remove all points from canvas...
  points.forEach((point) => {
    const { x, y } = point;
    const pointEl = fabricInst.getObjects().find((el) => el.left === x && el.top === y);
    fabricInst.remove(pointEl);
  });

  // Exit drawing mode...
  this.setState({ mode: "ready" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
