// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.get_canvasDataFromDB", inline: true };
const loggerMsgs = {
  eventStart: ["VecDrawTool", "API", "get_canvasDataFromDB"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_canvasDataFromDB(ASSEMBLAGEID) {
  return new Promise((resolve, reject) => {
    if (!ASSEMBLAGEID || ASSEMBLAGEID == undefined) reject("get_canvasDataFromDB(): No assemblage ID provided.");

    // Setup...
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Set up request...
    const req = new XMLHttpRequest();

    // Init. + send request...
    req.open("GET", "/api.assemblage.getFabricCanvasData/" + ASSEMBLAGEID, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const canvasData = JSON.parse(req.responseText).fabricCanvasData;
        if (!canvasData) reject("get_canvasDataFromDB(): No canvas data found.");
        resolve(canvasData);
      }
      if (req.status !== 200) reject("get_canvasDataFromDB(): Canvas data retrieval failed.");
    };
    req.send();
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
