// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active slide index: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeSlideIndex(CHANGES) {
  if (!("activeSlideIndex" in CHANGES)) return;

  // Setup...
  const { activeSlideIndex } = CHANGES;
  const { slides, activeSlideIndexDisplay } = this.ref;
  const { activeSlideSetID } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(activeSlideIndex), "default", { inline: true });

  // Show/Hide slides...
  const relevantSlides = slides.filter((el) => el.dataset.slideSetId === activeSlideSetID);
  relevantSlides.forEach((slide, i) => slide.setAttribute("data-is-hidden", i !== activeSlideIndex));

  // Updt. active slide index display...
  activeSlideIndexDisplay.textContent = activeSlideIndex + 1;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
