// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_hidden(CHANGES) {
  if (!("hidden" in CHANGES)) return;

  // Setup...
  const { hidden } = CHANGES;
  const logArgs = { eventName: "stChH_hidden", inline: true };
  this.logger("state-change", loggerMsgs.stateChange(hidden), "default", logArgs);

  // Toggle comp. visibility...
  this.element.setAttribute("data-is-hidden", hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
