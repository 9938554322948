// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_registerForm from "./init/init_registerForm.js";
import init_loginForm from "./init/init_loginForm.js";
import init_openSlotsDisplay from "./init/init_openSlotsDisplay.js";
import init_closeBtn from "./init/init_closeBtn.js";
import init_hideTransition from "./init/init_hideTransition.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_view from "./eventbusHandlers/ebh_swup_page_view.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_view from "./stateChangeHandlers/stChH_view.js";
import stChH_hidden from "./stateChangeHandlers/stChH_hidden.js";
import stChH_loadSpinnerHidden from "./stateChangeHandlers/stChH_loadSpinnerHidden.js";
import stChH_closeBtnHidden from "./stateChangeHandlers/stChH_closeBtnHidden.js";
import stChH_loginFormState from "./stateChangeHandlers/stChH_loginFormState.js";
import stChH_registerFormState from "./stateChangeHandlers/stChH_registerFormState.js";
import stChH_continueOrJoinCTAState from "./stateChangeHandlers/stChH_continueOrJoinCTAState.js";
import stChH_postRegisterCTAState from "./stateChangeHandlers/stChH_postRegisterCTAState.js";
import stChH_openSlotsDisplayState from "./stateChangeHandlers/stChH_openSlotsDisplayState.js";
import stChH_loginURL from "./stateChangeHandlers/stChH_loginURL.js";
import stChH_successfulLoginAttempts from "./stateChangeHandlers/stChH_successfulLoginAttempts.js";
import stChH_loginAttempts from "./stateChangeHandlers/stChH_loginAttempts.js";

import show_comp from "./api/show_comp.js";
import set_view from "./api/set_view.js";
import register_user from "./api/register_user.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class LoginModal extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      joinBtn: null,
      continueBtn: null,
      confirmContinueBtn: null,
      denyContinueBtn: null,
      loginForm: null,
      continueOrJoinCTA: null,
      postRegisterCTA: null,
      backToContinueOrJoinBtns: [],
      loginBtn: null,
      loginInput: null,
      notif_wrongAccessCode: null,
      registerForm: null,
      registerBtn: null,
      enterBtn: null,
      openSlotsNumber: null,
      accessCodeDisplay: null,
      closeBtn: null,
      loadSpinner: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "LoginModal",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_openSlotsDisplay = init_openSlotsDisplay.bind(this);
    this.init_registerForm = init_registerForm.bind(this);
    this.init_hideTransition = init_hideTransition.bind(this);
    this.init_loginForm = init_loginForm.bind(this);
    this.init_closeBtn = init_closeBtn.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      register_user: register_user.bind(this),
      set_view: set_view.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: show_comp.bind(this),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_page_view = ebh_swup_page_view.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_closeBtnHidden = stChH_closeBtnHidden.bind(this);
    this.stChL_successfulLoginAttempts = stChH_successfulLoginAttempts.bind(this);
    this.stChL_loginAttempts = stChH_loginAttempts.bind(this);
    this.stChL_loadSpinnerHidden = stChH_loadSpinnerHidden.bind(this);
    this.stChL_postRegisterCTAState = stChH_postRegisterCTAState.bind(this);
    this.stChL_openSlotsDisplayState = stChH_openSlotsDisplayState.bind(this);
    this.stChL_registerFormState = stChH_registerFormState.bind(this);
    this.stChL_loginURL = stChH_loginURL.bind(this);
    this.stChL_view = stChH_view.bind(this);
    this.stChL_continueOrJoinCTAState = stChH_continueOrJoinCTAState.bind(this);
    this.stChL_loginFormState = stChH_loginFormState.bind(this);
    this.stChL_hidden = stChH_hidden.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("LoginModal.api.register_user", this.api.register_user);
    eventbus.off("LoginModal.api.set_view", this.api.set_view);
    eventbus.off("LoginModal.api.hideComp", this.api.hideComp);
    eventbus.off("LoginModal.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_registerForm();
    this.init_loginForm();
    this.init_openSlotsDisplay();
    this.init_closeBtn();
    this.init_hideTransition();
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({
      view: this.options.initView ?? "continueOrJoin",
      loginURL: "null",
      accessCode: this.options.initAccessCode ?? "null",
      loginAttempts: 0,
      successfulLoginAttempts: 0,
      hidden: this.options.is_hidden ?? true,
      loadSpinnerHidden: true,
      is_mobile: window.innerWidth < 640,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    eventbus.on("swup_page_view", this.ebl_swup_page_view);
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("LoginModal.api.register_user", this.api.register_user);
    eventbus.on("LoginModal.api.set_view", this.api.set_view);
    eventbus.on("LoginModal.api.hideComp", this.api.hideComp);
    eventbus.on("LoginModal.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    ////////// closeBtnHidden //////////
    ////////////////////////////////////

    if ("closeBtnHidden" in CHANGES) this.stChL_closeBtnHidden(CHANGES);

    ///// successfulLoginAttempts /////
    ////////////////////////////////////

    if ("successfulLoginAttempts" in CHANGES) this.stChL_successfulLoginAttempts(CHANGES);

    ////////// loginAttempts //////////
    ////////////////////////////////////

    if ("loginAttempts" in CHANGES) this.stChL_loginAttempts(CHANGES);

    //////// loadSpinnerHidden ////////
    ////////////////////////////////////

    if ("loadSpinnerHidden" in CHANGES) this.stChL_loadSpinnerHidden(CHANGES);

    /////// postRegisterCTAState ///////
    ////////////////////////////////////

    if ("postRegisterCTAState" in CHANGES) this.stChL_postRegisterCTAState(CHANGES);

    ////// openSlotsDisplayState //////
    ////////////////////////////////////

    if ("openSlotsDisplayState" in CHANGES) this.stChL_openSlotsDisplayState(CHANGES);

    //////// registerFormState ////////
    ////////////////////////////////////

    if ("registerFormState" in CHANGES) this.stChL_registerFormState(CHANGES);

    ///////////// loginURL /////////////
    ////////////////////////////////////

    if ("loginURL" in CHANGES) this.stChL_loginURL(CHANGES);

    /////////////// view ///////////////
    ////////////////////////////////////

    if ("view" in CHANGES) this.stChL_view(CHANGES);

    ////// continueOrJoinCTAState //////
    ////////////////////////////////////

    if ("continueOrJoinCTAState" in CHANGES) this.stChL_continueOrJoinCTAState(CHANGES);

    ////////// loginFormState //////////
    ////////////////////////////////////

    if ("loginFormState" in CHANGES) this.stChL_loginFormState(CHANGES);

    ////////////// hidden //////////////
    ////////////////////////////////////

    if ("hidden" in CHANGES) this.stChL_hidden(CHANGES);

    /////////////// Mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default LoginModal;
