// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_enable(ARGS) {
  // Setup...
  const { _name, from } = ARGS;
  const logArgs = { eventName: "ebh_swup_enable" };
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // Set init. route state...
  const url = from.url;
  const urlParts = url.split("/");
  const pageID = urlParts[urlParts.length - 1];
  const parentPageID = urlParts[urlParts.length - 2];
  const currRoute = { url: from.url, pageID, parentPageID };
  this.setState({ route: currRoute });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
