// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { fabric } from "fabric";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_fabricCanvas_mouseDown", inline: true };
const loggerMsgs = {
  eventStart: ["an event occurred"],
};
const possibleModeStateValues = ["drawing"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_fabricCanvas_mouseDown(EVENTDATA) {
  // Setup...
  const { mode } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!possibleModeStateValues.includes(mode)) return console.warn("eh_fabricCanvas_mouseDown(): not in drawing mode");

  // Setup...
  const fabricInst = this.modules.fabric.instance;
  if (!fabricInst) return console.warn("eh_fabricCanvas_mouseDown(): no fabric instance found");

  ////////////////////////////////////////////////
  // Vector shape drawing (polygon from points) //
  ////////////////////////////////////////////////

  // Get curr. drawing session...
  const drawingSession = this.state.drawingSession || { points: [] };

  // Get the click coordinates...
  const { absolutePointer } = EVENTDATA;
  const { x, y } = absolutePointer;

  // Create a new point...
  const point = new fabric.Circle({
    radius: 6,
    fill: "red",
    left: x,
    top: y,
    selectable: false,
    hasControls: false,
    hasBorders: false,
  });

  // Add the point to the canvas...
  fabricInst.add(point);

  // Add point to drawing session...
  this.setState({ drawingSession: { points: [...drawingSession.points, { x, y }] } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
