// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  cancel_routeNotToBeTracked: ["ebh.: swup_enable", "cancelling user tracking init.", "route not to be tracked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function ebh_swup_enable(ARGS) {
  // Setup...
  const { from } = ARGS;
  const { trackingRules = [] } = this.options;
  const currRoute = window.location.pathname;
  const logArgs = { eventName: "ebh_eventbusCall" };
  this.logger("eventbus call", [ARGS], "event", logArgs);

  // Determ. & set init. route/page ID...
  const { url } = from;
  const urlParts = url.split("/");
  const pageID = urlParts[urlParts.length - 1];
  this.setState({ currPageId: pageID });

  // Guard: Match patterns of routes to be tracked (rules) against current route...
  if (!this.check_routeToBeTracked(currRoute, trackingRules))
    return this.logger("init", loggerMsgs.cancel_routeNotToBeTracked, "warning", { inline: true });

  // Init. new user session + session updates...
  await this.init_newUserSession();
  this.init_sessionUpdt();

  // Notify other comp...
  // - Online User Indicator: init. user count display
  eventbus.emit("UserTracker_ready", { pageID });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
