// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["shape picker was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_shapePicker_click() {
  // Setup...
  const { shapePicker } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Toggle selected shape...
  const prevSelectedShape = shapePicker.dataset.shape;
  const newSelectedShape = prevSelectedShape === "ellipse" ? "rectangle" : "ellipse";
  this.setState({ selectedShape: newSelectedShape });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
