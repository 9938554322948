// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.save_canvasSnapshot", inline: true };
const loggerMsgs = {
  eventStart: ["VecDrawTool", "API", "save_canvasSnapshot"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function save_canvasSnapshot() {
  // Setup...
  const { pageID } = this.options;
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!fabricInst) return console.warn("save_canvasSnapshot(): no fabric instance found");

  // Set up request...
  const req = new XMLHttpRequest();
  const data = new FormData();

  // Prep. req. data...
  const canvasData = fabricInst.toJSON();
  data.append("assemblageID", pageID);
  data.append("canvasData", JSON.stringify(canvasData));

  // Init. + send request...
  req.open("POST", "/api.assemblage.storeSnapshot", true);
  req.onreadystatechange = () => {
    if (req.readyState === 4 && req.status === 200) console.log("%cCanvas snapshot saved.", consoleStyles.success);
    if (req.status !== 200) console.log("%Canvas snapshot save failed.", consoleStyles.error);
  };
  req.send(data);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
