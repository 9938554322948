// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";
import check_routeMatchesRule from "./util/check_routeMatchesRule.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_backBtn from "./init/init_backBtn.js";
import init_openSlotsDisplay from "./init/init_openSlotsDisplay.js";
import init_onlineUsersDisplay from "./init/init_onlineUsersDisplay.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_enable from "./eventbusHandlers/ebh_swup_enable.js";
import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_UserTracker_ready from "./eventbusHandlers/ebh_UserTracker_ready.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_route from "./stateChangeHandlers/stChH_route.js";

import stChH_backBtnState from "./stateChangeHandlers/stChH_backBtnState.js";
import stChH_openSlotsDisplayState from "./stateChangeHandlers/stChH_openSlotsDisplayState.js";
import stChH_onlineUsersDisplayState from "./stateChangeHandlers/stChH_onlineUsersDisplayState.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Nav extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      backBtn: null,
      openSlotsDisplay: null,
      onlineUsersDisplay: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "Nav",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.check_routeMatchesRule = check_routeMatchesRule.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_onlineUsersDisplay = init_onlineUsersDisplay.bind(this);
    this.init_openSlotsDisplay = init_openSlotsDisplay.bind(this);
    this.init_backBtn = init_backBtn.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_UserTracker_ready = ebh_UserTracker_ready.bind(this);
    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_swup_enable = ebh_swup_enable.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_onlineUsersDisplayState = stChH_onlineUsersDisplayState.bind(this);
    this.stChL_openSlotsDisplayState = stChH_openSlotsDisplayState.bind(this);
    this.stChL_route = stChH_route.bind(this);
    this.stChL_backBtnState = stChH_backBtnState.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("swup_enable", this.ebl_swup_enable);
    eventbus.off("swup_page_load", this.ebl_swup_page_load);
    eventbus.off("UserTracker_ready", this.ebl_UserTracker_ready);
    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("Nav.api.hideComp", this.api.hideComp);
    eventbus.off("Nav.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_backBtn();
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({ mode: "init", is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("swup_enable", this.ebl_swup_enable);
    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("UserTracker_ready", this.ebl_UserTracker_ready);
    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("Nav.api.hideComp", this.api.hideComp);
    eventbus.on("Nav.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    ///// onlineUsersDisplayState /////
    ////////////////////////////////////

    if ("onlineUsersDisplayState" in CHANGES) this.stChL_onlineUsersDisplayState(CHANGES);

    ////// openSlotsDisplayState //////
    ////////////////////////////////////

    if ("openSlotsDisplayState" in CHANGES) this.stChL_openSlotsDisplayState(CHANGES);

    ////////////// route //////////////
    ////////////////////////////////////

    if ("route" in CHANGES) this.stChL_route(CHANGES);

    /////////// backBtnState ///////////
    ////////////////////////////////////

    if ("backBtnState" in CHANGES) this.stChL_backBtnState(CHANGES);

    /////////////// Mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Nav;
