// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`online-users-display state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_onlineUsersDisplayState(CHANGES) {
  if (!("onlineUsersDisplayState" in CHANGES)) return;

  // Setup...
  const { onlineUsersDisplayState } = CHANGES;
  const { onlineUsersDisplay } = this.ref;
  const logArgs = { eventName: "stChH_onlineUsersDisplayState" };
  this.logger("state-change", loggerMsgs.stateChange(onlineUsersDisplayState), "default", logArgs);

  // Execute nested is_hidden state-change...
  if ("is_hidden" in onlineUsersDisplayState) {
    const { is_hidden } = onlineUsersDisplayState;
    onlineUsersDisplay.setAttribute("data-is-hidden", is_hidden);
  }

  // Execute nested activeUserSessions state-change...
  if ("activeUserSessions" in onlineUsersDisplayState) {
    const { activeUserSessions } = onlineUsersDisplayState;
    const activeSessionCount = activeUserSessions.length;

    // Updt. user count number...
    const userCountNumber = onlineUsersDisplay.querySelector("[data-ref='userCountNumber']");
    if (userCountNumber) userCountNumber.innerHTML = activeSessionCount;

    // Toggle comp. active attr. if any active user sessions...
    onlineUsersDisplay.setAttribute("data-is-active", activeSessionCount > 0);

    // Switch label text to 'person' if only 1 active user session...
    userCountNumber.nextElementSibling.textContent = activeSessionCount === 1 ? "person online" : "people online";
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
