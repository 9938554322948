// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active user session count: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeSessionCount(CHANGES) {
  if (!("activeSessionCount" in CHANGES)) return;

  // Setup...
  const { activeSessionCount } = CHANGES;
  const { userCountDisplay } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeSessionCount), "default", { inline: true });

  // Toggle comp. active attr. if any active user sessions...
  this.element.setAttribute("data-is-active", activeSessionCount > 0);

  // Switch label text to 'person' if only 1 active user session...
  userCountDisplay.nextElementSibling.textContent = activeSessionCount === 1 ? "person online" : "people online";

  // Updt. user count display...
  userCountDisplay.innerHTML = activeSessionCount;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
