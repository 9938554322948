// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Cookies from "js-cookie";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["»Don’t show again« checkbox has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_dontShowAgainCheckbox_change(CHECKBOXEL, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // If checked, set cookie to hide info modal on subsequent visits...
  const { checked } = CHECKBOXEL;
  if (checked) Cookies.set("UdKAss_hideInfoModal", "true", { expires: 7 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
