// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function clearTile(TILEEL) {
  const circle = TILEEL.querySelector("svg circle");
  TILEEL.style.backgroundColor = "transparent";
  circle.style.fill = "transparent";
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["close-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_snapshotButton_click(EVENTDATA) {
  // Setup...
  const { clickableTiles } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  ////////////////////////
  // Tileset submission //
  ////////////////////////

  // Config. request...
  const route = "/home/tilesetSubmission";
  const request = new XMLHttpRequest();

  // Init. request...
  request.open("POST", route, true);

  // Response hdl...
  request.onreadystatechange = () => {
    console.log("request", request);
    if (request.readyState === 4 && request.status === 200) {
      console.log("request", request);

      // Clear all tiles...
      clickableTiles.forEach((el) => clearTile(el));
    }
  };

  // Send request...
  request.send();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
