// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function validate_loginURL(loginURL) {
  if (typeof loginURL !== "string" || loginURL === null || loginURL === undefined || loginURL.length === 0 || loginURL == "null")
    return false;
  return true;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_comp(ARGS) {
  // Setup...
  const { openSlots, loginURL, accessCode } = ARGS;

  // Updt. open-slots number if provided in API call args...
  if (openSlots !== undefined) this.setState({ openSlotsDisplayState: { openSlots } });

  // Updt. login URL & access code (state) if provided in API call args...
  if (validate_loginURL(loginURL)) this.setState({ loginURL });
  if (accessCode) this.setState({ accessCode });

  // Set comp. hidden state...
  this.setState({ hidden: false });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
