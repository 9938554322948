// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.cancel_drawing", inline: true };
const loggerMsgs = {
  eventStart: ["VecDrawTool", "API", "cancel_drawing"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function cancel_drawing() {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!fabricInst) return console.warn("cancel_drawing(): no fabric instance found");

  // Remove all points of curr. drawing session from canvas...
  const drawingSession = this.state.drawingSession || { points: [] };
  const points = drawingSession.points;
  points?.forEach((point) => {
    const { x, y } = point;
    const pointEl = fabricInst.getObjects().find((el) => el.left === x && el.top === y);
    fabricInst.remove(pointEl);
  });

  // Reset drawing session...
  this.setState({ drawingSession: { points: [] } });

  // Exit drawing mode...
  this.setState({ mode: "ready" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
