// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_backBtn_click from "../eventHandlers/eh_backBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. backBtn"],
  initSuccess: ["init. backBtn", "init. success"],
  noValidDOMEl: ["init. backBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_backBtn() {
  // Setup...
  const { backBtn } = this.ref;
  const logArgs = { eventName: "init_backBtn", inline: true };
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (backBtn === undefined || !this.validate_refEl(backBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click event on back btn...
  backBtn.addEventListener("click", eh_backBtn_click.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
