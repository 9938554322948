// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. animationLoop"],
  initSuccess: ["init. animationLoop", "init. success"],
  noValidDOMEl: ["init. animationLoop", "no valid DOM element provided"],
  noGridConfig: ["init. animationLoop", "no grid config. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_animationLoop() {
  // Setup...
  const { gridConfig } = this.options;
  const { clickableTiles } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (clickableTiles.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);
  if (!gridConfig) return this.cancel_featureInit(loggerMsgs.noGridConfig);

  // Loop through all colored tiles, when at last tile, start over. At each step, update the step state to the index of
  // the current tile's element. Wait 2 seconds between each step.
  const coloredTiles = clickableTiles.filter((el) => el.dataset.hasColor === "true");

  // Sort colored tiles nummerically by each elements data-index-in-set attribute...
  coloredTiles.sort((a, b) => {
    const aIndex = parseInt(a.dataset.indexInSet);
    const bIndex = parseInt(b.dataset.indexInSet);
    return aIndex - bIndex;
  });

  const stepDuration = 250;
  let i = 0;
  for (i; i < coloredTiles.length; i++) {
    const tileEl = coloredTiles[i];
    // const step = i + 1;

    // Reveal tile...
    tileEl.setAttribute("data-is-hidden", false);

    // Update step state...
    // this.setState({ step });

    // Wait 2 seconds...
    await new Promise((resolve) => setTimeout(resolve, stepDuration));

    // If last tile, start over...
    if (i === coloredTiles.length - 1) {
      // Hide all tiles...
      coloredTiles.forEach((el) => el.setAttribute("data-is-hidden", true));

      // Start over...
      i = -1;
    }
  }

  // Conclude...
  // this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
