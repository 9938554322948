// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Cookies from "js-cookie";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_dontShowAgainCheckbox_change from "../eventHandlers/eh_dontShowAgainCheckbox_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. cta_dontShowAgain"],
  initSuccess: ["init. cta_dontShowAgain", "init. success"],
  noValidDOMEl: ["init. cta_dontShowAgain", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cta_dontShowAgain() {
  // Setup...
  const { cta_dontShowAgain } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (cta_dontShowAgain === undefined || !this.validate_refEl(cta_dontShowAgain))
    return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to checkbox change event...
  const checkbox = cta_dontShowAgain.querySelector("input[type='checkbox']");
  if (checkbox) checkbox.addEventListener("change", eh_dontShowAgainCheckbox_change.bind(this, checkbox));

  // Set init. CTA state...
  const hideInfoModal = Cookies.get("UdKAss_hideInfoModal") === "true";
  this.setState({ ctaState_dontShowAgain: { hidden: hideInfoModal } });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
