// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_view", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`view: ${CHANGE}`],
  error_noStateChange: () => ["no change to view state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["continueOrJoin", "login", "register", "post-register"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_view(CHANGES) {
  if (!("view" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.view))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.view), "error", logArgs);

  // Setup...
  const { view } = CHANGES;
  const { continueOrJoinCTAState, loginFormState, registerFormState, postRegisterCTAState } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(view), "default", logArgs);

  // Execute »continueOrJoin« view state change...
  // ↳ Show continue-or-join-CTA
  // ↳ Hide login-form
  // ↳ Hide register-form
  // ↳ Hide post-register-CTA
  if (view === "continueOrJoin") {
    this.setState({
      continueOrJoinCTAState: { ...continueOrJoinCTAState, is_hidden: false },
      loginFormState: { ...loginFormState, is_hidden: true },
      registerFormState: { ...registerFormState, is_hidden: true },
      postRegisterCTAState: { ...postRegisterCTAState, is_hidden: true },
    });
  }

  // Execute »login« view state change...
  // ↳ Show login-form
  // ↳ Hide continue-or-join-CTA
  // ↳ Hide register-form
  // ↳ Hide post-register-CTA
  if (view === "login") {
    this.setState({
      continueOrJoinCTAState: { ...continueOrJoinCTAState, is_hidden: true },
      loginFormState: { ...loginFormState, is_hidden: false },
      registerFormState: { ...registerFormState, is_hidden: true },
      postRegisterCTAState: { ...postRegisterCTAState, is_hidden: true },
    });
  }

  // Execute »register« view state change...
  // ↳ Show register-form
  // ↳ Hide continue-or-join-CTA
  // ↳ Hide login-form
  // ↳ Hide post-register-CTA
  if (view === "register") {
    this.setState({
      continueOrJoinCTAState: { ...continueOrJoinCTAState, is_hidden: true },
      loginFormState: { ...loginFormState, is_hidden: true },
      registerFormState: { ...registerFormState, is_hidden: false },
      postRegisterCTAState: { ...postRegisterCTAState, is_hidden: true },
    });
  }

  // Execute »post-register« view state change...
  // ↳ Show post-register-CTA
  // ↳ Hide continue-or-join-CTA
  // ↳ Hide login-form
  // ↳ Hide register-form
  if (view === "post-register") {
    this.setState({
      continueOrJoinCTAState: { ...continueOrJoinCTAState, is_hidden: true },
      loginFormState: { ...loginFormState, is_hidden: true },
      registerFormState: { ...registerFormState, is_hidden: true },
      postRegisterCTAState: { ...postRegisterCTAState, is_hidden: false },
    });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
