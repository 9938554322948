// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_drawBtn_click from "../eventHandlers/eh_drawBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. drawBtn"],
  initSuccess: ["init. drawBtn", "init. success"],
  noValidDOMEl: ["init. drawBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_drawBtn() {
  // Setup...
  const { drawBtn, tooltip_drawBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEl(drawBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on draw button...
  drawBtn.addEventListener("click", eh_drawBtn_click.bind(this));

  // Init. tooltip...
  if (this.validate_refEl(tooltip_drawBtn)) {
    drawBtn.addEventListener("mouseenter", () => this.updt_tooltip(drawBtn, tooltip_drawBtn));
    drawBtn.addEventListener("mouseleave", () => tooltip_drawBtn.setAttribute("data-is-hidden", "true"));
  }

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
