// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["mouse entered canvas"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_canvas_mouseEnter(CANVASEL, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Calc. mouse pos. relative to canvas...
  const canvasRect = CANVASEL.getBoundingClientRect();
  const { clientX, clientY } = EVENTDATA;
  const { left, top } = canvasRect;
  const x = clientX - left;
  const y = clientY - top;

  // Updt. canvas mouse pos...
  this.setState({ canvasMousePos: { x, y } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
