// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

import save_tile from "./utilities/save_tile.js";
import delete_tile from "./utilities/delete_tile.js";
import clearTile from "./utilities/clearTile.js";
import updt_tooltip from "./utilities/updt_tooltip.js";
import draw_onCanvas from "./utilities/draw_onCanvas.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_editingDialog from "./init/init_editingDialog.js";
import init_shapePicker from "./init/init_shapePicker.js";
import init_colorPicker from "./init/init_colorPicker.js";
import init_canvas from "./init/init_canvas.js";
import init_drawBtn from "./init/init_drawBtn.js";
import init_cancelBtn from "./init/init_cancelBtn.js";
import init_saveBtn from "./init/init_saveBtn.js";
import init_clickableTiles from "./init/init_clickableTiles.js";
import init_snapshotButton from "./init/init_snapshotButton.js";

import deinit_canvas from "./init/deinit_canvas.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import stChH_canvasInst from "./stateChangeHandlers/stChH_canvasInst.js";
import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_editingDialogState from "./stateChangeHandlers/stChH_editingDialogState.js";
import stChH_selectedTile from "./stateChangeHandlers/stChH_selectedTile.js";
import stChH_selectedColor from "./stateChangeHandlers/stChH_selectedColor.js";
import stChH_selectedShape from "./stateChangeHandlers/stChH_selectedShape.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["tileconfig"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Coulage extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      clickableGrid: null,
      clickableTiles: [],
      snapshotButton: null,
      editingDialog: null,
      shapePicker: null,
      colorPicker: null,
      editingDialog: null,
      drawBtn: null,
      saveBtn: null,
      cancelBtn: null,
      //////
      tooltip_shapePicker: null,
      tooltip_colorPicker: null,
      tooltip_drawBtn: null,
      tooltip_saveBtn: null,
      tooltip_cancelBtn: null,
      tooltip_tileDeleteBtn: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "Coulage",
      version: element.getAttribute("g-version") ?? "1",
      tileConfig: options.tileconfig ?? null,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.save_tile = save_tile.bind(this);
    this.delete_tile = delete_tile.bind(this);
    this.clearTile = clearTile.bind(this);
    this.updt_tooltip = updt_tooltip.bind(this);
    this.draw_onCanvas = draw_onCanvas.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_canvas = init_canvas.bind(this);
    this.init_drawBtn = init_drawBtn.bind(this);
    this.init_editingDialog = init_editingDialog.bind(this);
    this.init_shapePicker = init_shapePicker.bind(this);
    this.init_cancelBtn = init_cancelBtn.bind(this);
    this.init_saveBtn = init_saveBtn.bind(this);
    this.init_colorPicker = init_colorPicker.bind(this);
    this.init_clickableTiles = init_clickableTiles.bind(this);
    this.init_snapshotButton = init_snapshotButton.bind(this);

    ////////// De-init. f() ////////////
    ////////////////////////////////////

    this.deinit_canvas = deinit_canvas.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_canvasInst = stChH_canvasInst.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_selectedShape = stChH_selectedShape.bind(this);
    this.stChL_selectedTile = stChH_selectedTile.bind(this);
    this.stChL_selectedColor = stChH_selectedColor.bind(this);
    this.stChL_editingDialogState = stChH_editingDialogState.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_editingDialog();
    this.init_snapshotButton();
    this.init_clickableTiles();
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({
      mode: "idle",
      selectedColor: "#000",
      selectedShape: "rectangle",
      is_mobile: window.innerWidth < 640,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    //////////// canvasInst ////////////
    ////////////////////////////////////

    if ("canvasInst" in CHANGES) this.stChL_canvasInst(CHANGES);

    /////////////// mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);

    ////////// selectedShape //////////
    ////////////////////////////////////

    if ("selectedShape" in CHANGES) this.stChL_selectedShape(CHANGES);

    /////////// selectedTile ///////////
    ////////////////////////////////////

    if ("selectedTile" in CHANGES) this.stChL_selectedTile(CHANGES);

    ////////// selectedColor //////////
    ////////////////////////////////////

    if ("selectedColor" in CHANGES) this.stChL_selectedColor(CHANGES);

    //////// editingDialogState ////////
    ////////////////////////////////////

    if ("editingDialogState" in CHANGES) this.stChL_editingDialogState(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Coulage;
