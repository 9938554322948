// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.log_canvasChange", inline: true };
const loggerMsgs = {
  eventStart: ["VecDrawTool", "API", "log_canvasChange"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function log_canvasChange(
  CONFIG = {
    disregardMode: false,
  }
) {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  const { disregardMode = false } = CONFIG;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!disregardMode) {
    if (this.state.mode == "init") return console.warn("log_canvasChange(): currently in init. mode, not logging canvas changes");
    if (this.state.mode == "drawing")
      return console.warn("log_canvasChange(): currently in drawing mode, not logging canvas changes");
  }
  if (!fabricInst) return console.warn("eh_fabricCanvas_mouseDown(): no fabric instance found");

  // Gather saved canvas data + current canvas data...
  const lastCanvasData_new = [...this.state.lastCanvasData];
  const storageLimit = 10;
  lastCanvasData_new.push({ data: fabricInst.toJSON(), timestamp: Date.now() });
  if (lastCanvasData_new.length > storageLimit) lastCanvasData_new.shift();

  // Take canvas snapshot...
  this.api.save_canvasSnapshot();

  // - Udpt. lastChanged state...
  // - Store latest canvas data to enable undo-btn...
  this.setState({
    lastChanged: Date.now(),
    lastCanvasData: lastCanvasData_new,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
