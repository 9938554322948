// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_drawBtn_click from "../eventHandlers/eh_drawBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_drawBtn", inline: true };
const loggerMsgs = {
  initStart: ["init. drawBtn"],
  initSuccess: ["init. drawBtn", "init. success"],
  noValidDOMEl: ["init. drawBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_drawBtn() {
  // Setup...
  const { drawBtn, tooltip_drawBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(drawBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  drawBtn.addEventListener("click", eh_drawBtn_click.bind(this));

  // Init. tooltip...
  this.init_tooltip(drawBtn, tooltip_drawBtn);

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
