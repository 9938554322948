// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`back-btn. state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_backBtnState(CHANGES) {
  if (!("backBtnState" in CHANGES)) return;

  // Setup...
  const { backBtnState } = CHANGES;
  const { backBtn } = this.ref;
  const logArgs = { eventName: "stChH_backBtnState" };
  this.logger("state-change", loggerMsgs.stateChange(backBtnState), "default", logArgs);

  // Guard...
  if (!backBtn) return this.logger("error", ["Back-btn not found."], "error", logArgs);

  // Execute nested is_hidden state-change...
  if ("is_hidden" in backBtnState) {
    const { is_hidden } = backBtnState;
    backBtn.setAttribute("data-is-hidden", is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
