// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["hide-transition has ended"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_hideTransition_end() {
  // Setup...
  const { hidden } = this.state;
  const logArgs = { eventName: "eh_hideTransition_end", inline: true };
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // If modal is hidden, reset its view to »continue-or-join-CTA« + hide load spinner...
  if (hidden)
    this.setState({
      view: "continueOrJoin",
      loadSpinnerHidden: true,
    });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
