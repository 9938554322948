// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_zoomBtn_click from "../eventHandlers/eh_zoomBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_zoomBtns", inline: true };
const loggerMsgs = {
  initStart: ["init. zoomBtns"],
  initSuccess: ["init. zoomBtns", "init. success"],
  noValidDOMEl: ["init. zoomBtns", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_zoomBtns() {
  // Setup...
  const { zoomOutBtn, zoomInBtn, tooltip_zoomOutBtn, tooltip_zoomInBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if ([zoomOutBtn, zoomInBtn].every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  if (this.validate_refEl(zoomOutBtn)) zoomOutBtn.addEventListener("click", eh_zoomBtn_click.bind(this, zoomOutBtn));
  if (this.validate_refEl(zoomInBtn)) zoomInBtn.addEventListener("click", eh_zoomBtn_click.bind(this, zoomInBtn));

  // Init. tooltip...
  this.init_tooltip(zoomOutBtn, tooltip_zoomOutBtn);
  this.init_tooltip(zoomInBtn, tooltip_zoomInBtn);

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
