// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function delete_tile(TILEEL) {
  // Setup...
  if (!TILEEL) return console.warn("delete_tile(): No tile el. provided.");

  // Create updt. request...
  const request = new XMLHttpRequest();
  const data = new FormData();

  // Prep. tile data...
  const { col, row } = TILEEL.dataset;
  data.append("col", col);
  data.append("row", row);

  // Init. updt. request...
  request.open("POST", "api.coulage.deleteTile", true);

  // Set tile el. load spinner active...
  const loadSpinner = TILEEL.querySelector("[data-ref='loadSpinner']");
  if (loadSpinner) loadSpinner.setAttribute("data-is-active", true);

  // Response hdl...
  request.onreadystatechange = () => {
    if (request.readyState === 4 && request.status === 200) {
      loadSpinner.setAttribute("data-is-active", false);

      // Reset tile el. styles & attributes...
      TILEEL.style.setProperty("--color", "transparent");
      TILEEL.setAttribute("data-has-color", false);
      TILEEL.setAttribute("data-is-active", false);

      // Clear tile canvas img...
      const canvasImg = TILEEL.querySelector("[data-ref='canvasImg']");
      if (canvasImg) canvasImg.remove();
    }
  };

  // Send updt. request...
  request.send(data);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
