// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. userCountDisplay"],
  initSuccess: ["init. userCountDisplay", "init. success"],
  noValidDOMEl: ["init. userCountDisplay", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_userCountDisplay() {
  // Setup...
  const { userCountDisplay } = this.ref;
  const { currPageId } = this.state;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (userCountDisplay === undefined || !this.validate_refEl(userCountDisplay))
    return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Get init. active user count (for curr. page ID) from DB...
  const req = new XMLHttpRequest();
  req.open("GET", "/api.get.activeUserSessions/" + currPageId, true);
  req.setRequestHeader("Content-Type", "application/json");
  req.onreadystatechange = () => {
    if (req.readyState === 4 && req.status === 200) {
      const activeUserSessions = JSON.parse(req.responseText);
      if (!activeUserSessions) return console.warn("init_userCountDisplay(): No active user sessions found.");
      this.setState({ activeSessionCount: activeUserSessions.length });
    }
  };
  req.send();

  // Every 10 seconds, updt. active user count...
  const userCountUpdtInterval = setInterval(() => {
    const req = new XMLHttpRequest();
    req.open("GET", "/api.get.activeUserSessions/" + currPageId, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const activeUserSessions = JSON.parse(req.responseText);
        if (!activeUserSessions) return console.warn("init_userCountDisplay(): No active user sessions found.");
        this.setState({ activeSessionCount: activeUserSessions.length });
      }
    };
    req.send();
  }, 10000);
  this.setState({ userCountUpdtIntervalID: userCountUpdtInterval });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
