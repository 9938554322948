// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { v4 as uuidv4 } from "uuid";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_canvas_mouseEnter from "../eventHandlers/eh_canvas_mouseEnter.js";
import eh_canvas_mouseDown from "../eventHandlers/eh_canvas_mouseDown.js";
import eh_canvas_mouseMove from "../eventHandlers/eh_canvas_mouseMove.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. canvas"],
  initSuccess: ["init. canvas", "init. success"],
  noValidDOMEl: ["init. canvas", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_canvas(CANVASEL) {
  // Setup...
  const { selectedTile } = this.state;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEl(CANVASEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Enable canvas...
  CANVASEL.setAttribute("data-is-enabled", "true");

  // Set canvas size...
  const { width, height } = CANVASEL.getBoundingClientRect();
  CANVASEL.width = width;
  CANVASEL.height = height;

  // Listen to mouse event on canvas...
  const el_canvas_mouseEnter = eh_canvas_mouseEnter.bind(this, CANVASEL);
  const el_canvas_mouseDown = eh_canvas_mouseDown.bind(this, CANVASEL);
  const el_canvas_mouseMove = eh_canvas_mouseMove.bind(this, CANVASEL);
  CANVASEL.addEventListener("mouseenter", el_canvas_mouseEnter);
  CANVASEL.addEventListener("mousedown", el_canvas_mouseDown);
  CANVASEL.addEventListener("mousemove", el_canvas_mouseMove);

  // Create canvas instance...
  const canvasInst = {
    id: uuidv4(),
    el: CANVASEL,
    ctx: CANVASEL.getContext("2d"),
    eventListeners: {
      mouseEnter: el_canvas_mouseEnter,
      mouseDown: el_canvas_mouseDown,
      mouseMove: el_canvas_mouseMove,
    },
  };

  // Load pre-existing canvas img. from corresponding tile (if available)...
  if (selectedTile && selectedTile.el) {
    const canvasImg = selectedTile.el.querySelector("[data-ref='canvasImg']");
    if (canvasImg) {
      const img = new Image();
      img.src = canvasImg.src;
      canvasInst.ctx.drawImage(img, 0, 0);
    }
  }

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
  this.setState({ canvasInst });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
