// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active slide set ID: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeSlideSetID(CHANGES) {
  if (!("activeSlideSetID" in CHANGES)) return;

  // Setup...
  const { activeSlideSetID } = CHANGES;
  const { slideSets, slideSetBtns, slides } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeSlideSetID), "default", { inline: true });

  // Show/hide slide sets...
  slideSets.forEach((el) => el.setAttribute("data-is-hidden", el.dataset.id !== activeSlideSetID));

  // Set slide-set-btns. active/inactive...
  slideSetBtns.forEach((el) => el.setAttribute("data-is-active", el.dataset.id === activeSlideSetID));

  // - Set slide counter max...
  // - Reset active slide index...
  const relevantSlides = slides.filter((el) => el.dataset.slideSetId === activeSlideSetID);
  this.setState({ slideCounterMax: relevantSlides.length, activeSlideIndex: 0 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
