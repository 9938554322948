// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_selectedColor", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`selected color: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedColor(CHANGES) {
  if (!("selectedColor" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { selectedColor } = CHANGES;
  const { mode } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(selectedColor), "default", logArgs);

  // Log last color select. timestamp...
  this.setState({ lastColorSelect: Date.now() });

  // If in »editing« mode, update the color of the selected shape...
  if (mode === "editing") {
    const fabricInst = this.modules.fabric.instance;
    if (!fabricInst) return console.warn("stChH_selectedColor(): no fabric instance found");
    const selectedShape = fabricInst.getActiveObject();
    if (!selectedShape) return console.warn("stChH_selectedColor(): no shape selected");
    selectedShape.set("fill", selectedColor);
    fabricInst.renderAll();
  }

  // If in »free-drawing« mode, update the color of the free-drawing brush...
  if (mode === "free-drawing") {
    const fabricInst = this.modules.fabric.instance;
    if (!fabricInst) return console.warn("stChH_selectedColor(): no fabric instance found");
    fabricInst.freeDrawingBrush.color = selectedColor;
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
