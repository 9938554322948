// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { v4 as uuidv4 } from "uuid";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. new user session"],
  initSuccess: ["init. new user session", "init. success"],
  newSessionCreated: ["init. new user session", "new session created"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_newUserSession() {
  return new Promise(async (resolve, reject) => {
    // Setup...
    const logArgs = { eventName: "init_newUserSession", inline: true };
    const { currPageId = "NO PAGE ID FOUND IN STATE" } = this.state;
    this.logger("init", loggerMsgs.initStart, "action", logArgs);

    // Create + store new user session...
    const userSession = { id: uuidv4(), relatedAssemblageId: currPageId, lastActive: new Date() };
    await this.api.store_userSession(userSession);
    this.setState({ currUserSession: userSession }); // ← store new session data in state for local use
    this.logger("init", loggerMsgs.newSessionCreated, "success", { inline: true });

    // Conclude...
    this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
    resolve();
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
