// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_toolTip", inline: true };
const loggerMsgs = {
  initStart: ["init. toolTip"],
  initSuccess: ["init. toolTip", "init. success"],
  noValidDOMEl: ["init. toolTip", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_toolTip(PARENTEL, TOOLTIPEL) {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(PARENTEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (!this.validate_refEl(TOOLTIPEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Init. show/hide via mouseenter/mouseleave...
  PARENTEL.addEventListener("mouseenter", () => this.updt_tooltip(PARENTEL, TOOLTIPEL));
  PARENTEL.addEventListener("mouseleave", () => TOOLTIPEL.setAttribute("data-is-hidden", "true"));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
