// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`»Don’t show again«-CTA state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_ctaState_dontShowAgain(CHANGES) {
  if (!("ctaState_dontShowAgain" in CHANGES)) return;

  // Setup...
  const { ctaState_dontShowAgain } = CHANGES;
  const { cta_dontShowAgain } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(ctaState_dontShowAgain));

  if (!cta_dontShowAgain) return;

  // Execute hidden state change...
  if ("hidden" in ctaState_dontShowAgain) cta_dontShowAgain.setAttribute("data-is-hidden", ctaState_dontShowAgain.hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
