// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`editing dialog state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_editingDialogState(CHANGES) {
  if (!("editingDialogState" in CHANGES)) return;

  // Setup...
  const { editingDialogState } = CHANGES;
  const { mode, position } = editingDialogState;
  const { editingDialog } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(editingDialogState));

  // Execute mode change...
  if ("mode" in editingDialogState) {
    const is_active = mode === "active";
    editingDialog.setAttribute("data-is-active", is_active);
  }

  // Execute position change...
  if (position && "position" in editingDialogState) {
    const { x, y } = position;
    editingDialog.style.setProperty("--x", `${x}px`);
    editingDialog.style.setProperty("--y", `${y}px`);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
