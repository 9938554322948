// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_userCountDisplay from "./init/init_userCountDisplay.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_UserTracker_ready from "./eventbusHandlers/ebh_UserTracker_ready.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_currPageId from "./stateChangeHandlers/stChH_currPageId.js";
import stChH_activeSessionCount from "./stateChangeHandlers/stChH_activeSessionCount.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  logStyles: { default: "#4361BC", action: "#c7d0ff", event: "#97a5ce", warning: "#ffaf00", error: "#ff3232" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class OnlineUsersIndicator extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = { userCountDisplay: null };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "OnlineUsersIndicator",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      run_withLogs: false,
      logStyles: defaultOptions.logStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_userCountDisplay = init_userCountDisplay.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_UserTracker_ready = ebh_UserTracker_ready.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_currPageId = stChH_currPageId.bind(this);
    this.stChL_activeSessionCount = stChH_activeSessionCount.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    // Clear user-count-updt. interval...
    clearInterval(this.state.userCountUpdtIntervalID);

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("UserTracker_ready", this.ebl_UserTracker_ready);
    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("OnlineUsersIndicator.api.hideComp", this.api.hideComp);
    eventbus.off("OnlineUsersIndicator.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({ mode: "init", is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("UserTracker_ready", this.ebl_UserTracker_ready);
    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("OnlineUsersIndicator.api.hideComp", this.api.hideComp);
    eventbus.on("OnlineUsersIndicator.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    //////////// currPageId ////////////
    ////////////////////////////////////

    if ("currPageId" in CHANGES) this.stChL_currPageId(CHANGES);

    //////// activeSessionCount ////////
    ////////////////////////////////////

    if ("activeSessionCount" in CHANGES) this.stChL_activeSessionCount(CHANGES);

    /////////////// Mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default OnlineUsersIndicator;
