// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`open-slots-display state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_openSlotsDisplayState(CHANGES) {
  if (!("openSlotsDisplayState" in CHANGES)) return;

  // Setup...
  const { openSlotsDisplayState } = CHANGES;
  const { openSlotsDisplay } = this.ref;
  const logArgs = { eventName: "stChH_openSlotsDisplayState" };
  this.logger("state-change", loggerMsgs.stateChange(openSlotsDisplayState), "default", logArgs);

  if (!openSlotsDisplay) return;

  // Execute nested is_hidden state-change...
  if ("is_hidden" in openSlotsDisplayState) {
    const { is_hidden } = openSlotsDisplayState;
    openSlotsDisplay.setAttribute("data-is-hidden", is_hidden);
  }

  // Execute nested participantCount state-change...
  if ("participantCount" in openSlotsDisplayState) {
    const participantMax = 20;
    const { participantCount } = openSlotsDisplayState;
    const openSlotsNumber = openSlotsDisplay.querySelector("[data-ref='openSlotsNumber']");
    openSlotsNumber.innerHTML = participantMax - participantCount;
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
