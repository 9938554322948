// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_string_hasJSONStructure from "../../../../../app/baseUtilities/check/check_string_hasJSONStructure";

function format_assemblageData(DATA) {
  if (!DATA) return console.warn("format_assemblageData(): No assemblage data provided.");

  // Extract all properties from data, format any JSON strings, & return formatted data...
  const formattedData = {};
  for (const [key, value] of Object.entries(DATA)) {
    if (check_string_hasJSONStructure(value)) {
      formattedData[key] = JSON.parse(value);
      // Check for any nested JSON strings to format...
      for (const [nestedKey, nestedValue] of Object.entries(formattedData[key])) {
        if (check_string_hasJSONStructure(nestedValue)) {
          formattedData[key][nestedKey] = JSON.parse(nestedValue);
        }
      }
    } else formattedData[key] = value;
  }
  return formattedData;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function fetch_assemblages() {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("GET", "api.assemblage.getAll", true);
    req.setRequestHeader("Content-Type", "application/json");
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const assemblagesData = JSON.parse(req.responseText);
        if (!assemblagesData) return console.warn("fetch_assemblagesData(): No assemblages data found.");
        const formattedAssemblagesData = assemblagesData.map((ass) => format_assemblageData(ass));
        resolve(formattedAssemblagesData);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.warn("fetch_assemblagesData(): Request failed.");
        reject();
      }
    };
    req.send();
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
