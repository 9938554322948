// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { fabric } from "fabric";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_fabricCanvas_mouseDown from "../eventHandlers/eh_fabricCanvas_mouseDown.js";
import eh_fabricCanvas_objMoving from "../eventHandlers/eh_fabricCanvas_objMoving.js";
import eh_fabricCanvas_objSelect from "../eventHandlers/eh_fabricCanvas_objSelect.js";
import eh_fabricCanvas_objDeselct from "../eventHandlers/eh_fabricCanvas_objDeselct.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_fabric", inline: true };
const loggerMsgs = {
  initStart: ["init. fabric"],
  initSuccess: ["init. fabric", "init. success"],
  noValidDOMEl: ["init. fabric", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function init_fabric(CONFIG = { restoreOnInit: true }) {
  // Setup...
  const { canvas } = this.ref;
  const { restoreOnInit } = CONFIG;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(canvas)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // fabric instantiation...
  window.fabricInst = new fabric.Canvas(canvas, {
    backgroundColor: "rgba(255, 255, 255, 0)",
    selection: false,
    width: canvas.offsetWidth,
    height: canvas.offsetHeight,
  });

  // Set init. zoom level...
  const center = fabricInst.getCenter();
  const centerPoint = new fabric.Point(center.left, center.top);
  fabricInst.zoomToPoint(centerPoint, 0.5);
  fabricInst.renderAll();

  // Init. canvas panning...
  this.init_fabric_canvasPan(fabricInst, { render_canvasEdgeMarkers: !restoreOnInit });

  // Init. grid background pattern...
  this.init_fabric_bgGrid(fabricInst);

  // Restore canvas data in DB...
  if (restoreOnInit) {
    const canvasData = await this.api.get_canvasDataFromDB(this.options.pageID);
    if (canvasData)
      fabricInst.loadFromJSON(canvasData, () => {
        fabricInst.backgroundColor = "rgba(255, 255, 255, 0)";
        fabricInst.renderAll();
        this.setState({ mode: "ready" });
      });
  } else this.setState({ mode: "ready" });

  // Event hdl...
  fabricInst.on("mouse:down", eh_fabricCanvas_mouseDown.bind(this));
  // fabricInst.on("object:moving", eh_fabricCanvas_objMoving.bind(this));
  fabricInst.on("selection:created", eh_fabricCanvas_objSelect.bind(this));
  fabricInst.on("selection:updated", eh_fabricCanvas_objSelect.bind(this));
  fabricInst.on("selection:cleared", eh_fabricCanvas_objDeselct.bind(this));

  // Log canvas changes (store latest canvas state to enable undo-btn. + updt. lastChanged state)...
  fabricInst.on("object:added", () => this.api.log_canvasChange());
  fabricInst.on("object:modified", () => this.api.log_canvasChange());
  fabricInst.on("object:removed", () => this.api.log_canvasChange());

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
  this.modules.fabric.instance = fabricInst;
  return fabricInst;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
