// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_saveBtn_click from "../eventHandlers/eh_saveBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. saveBtn"],
  initSuccess: ["init. saveBtn", "init. success"],
  noValidDOMEl: ["init. saveBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_saveBtn() {
  // Setup...
  const { saveBtn, tooltip_saveBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEl(saveBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on save button...
  saveBtn.addEventListener("click", eh_saveBtn_click.bind(this, saveBtn));

  // Init. tooltip...
  if (this.validate_refEl(tooltip_saveBtn)) {
    saveBtn.addEventListener("mouseenter", () => this.updt_tooltip(saveBtn, tooltip_saveBtn, { offset: 34 }));
    saveBtn.addEventListener("mouseleave", () => tooltip_saveBtn.setAttribute("data-is-hidden", "true"));
  }

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
