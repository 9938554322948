// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_prevNextBtn_click from "../eventHandlers/eh_prevNextBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. controls"],
  initSuccess: ["init. controls", "init. success"],
  noValidDOMEl: ["init. controls", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_controls() {
  // Setup...
  const { prevBtn, nextBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if ([prevBtn, nextBtn].every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on prev/next btn...
  if (prevBtn) prevBtn.addEventListener("click", eh_prevNextBtn_click.bind(this, prevBtn));
  if (nextBtn) nextBtn.addEventListener("click", eh_prevNextBtn_click.bind(this, nextBtn));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
