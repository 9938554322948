// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`selected shape: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedShape(CHANGES) {
  if (!("selectedShape" in CHANGES)) return;

  // Setup...
  const { selectedShape } = CHANGES;
  const { shapePicker } = this.ref;
  const { selectedTile } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(selectedShape), "default", { inline: true });

  // Updt. shape picker shape attr...
  shapePicker.setAttribute("data-shape", selectedShape);

  // If tile is selected, updt. its shape attr...
  if (selectedTile?.el && selectedTile?.el !== "null") selectedTile.el.setAttribute("data-shape", selectedShape);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
