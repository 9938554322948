// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_bgGridToggleBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["grid-toggle btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_bgGridToggleBtn_click(FABRICINST, GRIDPATTERN, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  if (!FABRICINST || !GRIDPATTERN)
    return console.warn("eh_bgGridToggleBtn_click(): no valid fabric instance or grid pattern provided");

  // Toggle grid visibility...
  const { gridVisible } = this.modules.fabric;
  FABRICINST.backgroundColor = gridVisible ? "rgba(255, 255, 255, 0)" : GRIDPATTERN;
  FABRICINST.renderAll();

  // Updt. grid visibility state...
  this.modules.fabric.gridVisible = !gridVisible;

  // Updt. btn. text...
  const btnText = gridVisible ? "Show Grid" : "Hide Grid";
  EVENTDATA.target.innerText = btnText;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
