// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_saveBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["save-btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_saveBtn_click() {
  // Setup...
  const { mode } = this.state;
  const { pageID } = this.options;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (mode === "init" || mode === "editing" || mode === "drawing")
    return console.warn("eh_saveBtn_click(): not in the right mode");

  ////////////////////////
  // Canvas data update //
  ////////////////////////

  // Get curr. canvas data...
  const fabricInst = this.modules.fabric.instance;
  if (!fabricInst) return console.warn("eh_saveBtn_click(): no fabric instance found");
  const canvasData = fabricInst.toJSON();

  // Updt. canvas data in DB...
  await this.api.updt_canvasDataInDB({ canvasData, assemblageID: pageID });

  // Updt. last saved...
  this.setState({ lastSaved: Date.now() });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
