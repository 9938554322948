// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`selectedTile:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedTile(CHANGES) {
  if (!("selectedTile" in CHANGES)) return;

  // Setup...
  const { selectedTile } = CHANGES;
  const { el } = selectedTile;
  const { clickableGrid, clickableTiles } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(selectedTile));

  /////////////////
  // El. changes //
  /////////////////

  if (el) {
    // Set active/inactive...
    clickableTiles.forEach((t) => t.setAttribute("data-is-active", t === el));

    // Register in grid el. that tile has been selected (via data-attr.)...
    clickableGrid.setAttribute("data-tile-is-selected", el !== "null");

    // If clicked tile already has color & shape, select both...
    if (el instanceof HTMLElement) {
      const tileColor = el.getAttribute("data-has-color") === "true" ? el.style.getPropertyValue("--color") : null;
      const tileShape = el.dataset.shape != "" ? el.dataset.shape : null;
      if (tileColor) this.setState({ selectedColor: tileColor });
      if (tileShape) this.setState({ selectedShape: tileShape });
    }

    // Reset mode to »idle« if no tile is selected...
    if (el === "null") this.setState({ mode: "idle" });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
