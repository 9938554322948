// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_lastColorSelect", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`last color select time: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
  loggingCanvasChange: (TIME) => [`No new color has been selected in the last ${TIME}ms`, "logging canvas changes"],
};

// const possibleStateValues = ["foo", "bar"];

let canvasLogInterval;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_lastColorSelect(CHANGES) {
  if (!("lastColorSelect" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { lastColorSelect } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(lastColorSelect), "default", logArgs);

  // Log canvas changes if no immediate color selection change occurs...
  if (this.state.mode === "init") return;
  clearInterval(canvasLogInterval);
  canvasLogInterval = setInterval(() => {
    this.logger("event", loggerMsgs.loggingCanvasChange(500), "action", logArgs);
    this.api.log_canvasChange();
    clearInterval(canvasLogInterval);
  }, 500);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
