// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_closeBtnHidden", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`close-btn. hidden: ${CHANGE}`],
  error_noStateChange: () => ["No change to closeBtnHidden state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_closeBtnHidden(CHANGES) {
  if (!("closeBtnHidden" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.closeBtnHidden))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.change), "error", logArgs);

  // Setup...
  const { closeBtnHidden } = CHANGES;
  const { closeBtn } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(closeBtnHidden), "default", logArgs);

  // Toggle close button visibility...
  closeBtn.setAttribute("data-is-hidden", closeBtnHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
