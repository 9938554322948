// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["preview-join-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_previewJoinBtn_click(PREVIEWEL) {
  // Setup...
  const { previewsStates } = this.state;
  const logArgs = { eventName: "eh_previewJoinBtn_click", inline: true };
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Open login-modal...
  const previewID = PREVIEWEL.dataset.id;
  const previewState = previewsStates.find(({ id }) => id === previewID);
  const openSlots = previewState?.config?.maxParticipants - previewState.participants.length;
  const assemblageURL = `/assemblages/${previewID}`;
  const accessCode = previewState.accessCode;
  const args = { openSlots, loginURL: assemblageURL, accessCode };
  eventbus.emit("LoginModal.api.showComp", args);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
