// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`previews-states:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_previewsStates(CHANGES) {
  if (!("previewsStates" in CHANGES)) return;

  // Setup...
  const { previewsStates } = CHANGES;
  const { previews: previewEls } = this.ref;
  const logArgs = { eventName: "stChH_previewsState" };
  this.logger("state-change", loggerMsgs.stateChange(previewsStates), "default", logArgs);

  ///////////////////////////
  // Preview element updt. //
  ///////////////////////////

  previewEls.forEach((el, i) => {
    const matchingState = previewsStates.find((s) => s.id === el.dataset.id);
    if (!matchingState) return;

    // Updt. preview’s open-slots display & subsequent data-is-active attr...
    const openSlotsDisplay = el.querySelector("[data-ref='openSlotsDisplay']");
    if (!openSlotsDisplay) return;
    const { participants } = matchingState;
    const { maxParticipants } = matchingState?.config;
    openSlotsDisplay.textContent = `${maxParticipants - participants.length}/${maxParticipants}`;
    el.setAttribute("data-is-active", participants.length < maxParticipants);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
