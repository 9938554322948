// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`current animation step: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_step(CHANGES) {
  if (typeof CHANGES.step === "undefined" || CHANGES.step === null || CHANGES.step === "") return;

  // Setup...
  const { step } = CHANGES;
  const { stepDisplay } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(step), "default", { inline: true });

  // Updt. step display...
  stepDisplay.querySelector("span").innerHTML = step;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
