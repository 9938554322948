// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["tile was clicked"],
  deleteButtonClick: ["click on tile", "tile delete-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_tile_click(TILEEL, EVENTDATA) {
  // Stop, if target was delete button...
  const target = EVENTDATA.target;
  const deleteBtnWasClicked = target.dataset.ref === "deleteButton" || target.closest("[data-ref='deleteButton']") !== null;
  if (deleteBtnWasClicked) return this.logger("event", loggerMsgs.deleteButtonClick, "event", { inline: true });

  // Setup...
  const { config_editingdialog } = this.options;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  ////////////////////////////
  // Editing dialog opening //
  ////////////////////////////

  // Activate color picker & set its position (centered below tile)...
  if (config_editingdialog.is_enabled) {
    const tileRect = TILEEL.getBoundingClientRect();
    const position = { x: tileRect.left + tileRect.width / 2, y: tileRect.bottom + 16 };
    this.setState({ editingDialogState: { mode: "active", position } });
  }

  ////////////////////
  // Tile selection //
  ////////////////////

  this.setState({ selectedTile: { el: TILEEL } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
