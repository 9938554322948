// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. window events"],
  initSuccess: ["init. window events", "init. success"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_windowEvents() {
  // Setup...
  const logArgs = { eventName: "init_windowEvents", inline: true };
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  // ...

  // Delete user session when tab is closed...
  window.addEventListener("beforeunload", () => {
    const { currUserSession } = this.state;
    if (!currUserSession) return;
    this.logger("event", ["beforeunload", "user session deleted"], "action", { inline: true });
    this.api.delete_userSession(currUserSession);
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
