// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_registerBtn_click from "../eventHandlers/eh_registerBtn_click.js";
import eh_enterBtn_click from "../eventHandlers/eh_enterBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_registerForm", inline: true };
const loggerMsgs = {
  initStart: ["init. registerForm"],
  initSuccess: ["init. registerForm", "init. success"],
  noValidDOMEl: ["init. registerForm", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_registerForm() {
  // Setup...
  const { registerForm, registerBtn, enterBtn } = this.ref;
  const refs = [registerForm, registerBtn, enterBtn];
  const { backBtns_are_initHidden } = this.options;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (refs === undefined || refs.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on register & enter btn...
  registerBtn?.addEventListener("click", eh_registerBtn_click.bind(this));
  enterBtn?.addEventListener("click", eh_enterBtn_click.bind(this));

  // Set init. register-form state...
  const registerFormState = { is_hidden: true, backBtn_is_hidden: backBtns_are_initHidden };
  this.setState({ registerFormState });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
