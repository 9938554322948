// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`user session updt. interval ID: ${CHANGE}`],
  error_noStateChange: () => ["No change to current user session updt. interval ID state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_userSessionUpdtIntervalID(CHANGES) {
  let logArgs = { eventName: "stChH_userSessionUpdtIntervalID", inline: true };
  if (!("userSessionUpdtIntervalID" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { userSessionUpdtIntervalID } = CHANGES;
  logArgs = { eventName: "stChH_userSessionUpdtIntervalID", inline: true };
  this.logger("state-change", loggerMsgs.stateChange(userSessionUpdtIntervalID), "default", logArgs);

  // [action]...
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
