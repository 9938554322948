// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_lastSaved", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`last saved: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_lastSaved(CHANGES) {
  if (!("lastSaved" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { lastSaved } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(lastSaved), "default", logArgs);

  // Updt. showAlertBeforeUnload global state (local storage)...
  const globalState = localStorage.getItem("globalState");
  localStorage.setItem("globalState", JSON.stringify({ ...JSON.parse(globalState), alertBeforeUnload: false }));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
