// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_freeDrawBtn_click from "../eventHandlers/eh_freeDrawBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_freeDrawBtn", inline: true };
const loggerMsgs = {
  initStart: ["init. freeDrawBtn"],
  initSuccess: ["init. freeDrawBtn", "init. success"],
  noValidDOMEl: ["init. freeDrawBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_freeDrawBtn() {
  // Setup...
  const { freeDrawBtn, tooltip_freeDrawBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(freeDrawBtn)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  freeDrawBtn.addEventListener("click", eh_freeDrawBtn_click.bind(this));

  // Init. tooltip...
  this.init_tooltip(freeDrawBtn, tooltip_freeDrawBtn);

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
