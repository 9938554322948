// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_deleteBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["delete-btn. has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_deleteBtn_click() {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  if (!fabricInst) return console.warn("eh_deleteBtn_click(): no fabric instance found");

  // Delete selected objects...
  const selectedObjs = fabricInst.getActiveObjects();
  selectedObjs.forEach((obj) => fabricInst.remove(obj));
  fabricInst.discardActiveObject().renderAll();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
