// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger.js";
import set_state from "../../baseUtilities/state/set_state";
import check_routeToBeTracked from "./util/check_routeToBeTracked.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_newUserSession from "./init/init_newUserSession.js";
import init_sessionUpdt from "./init/init_sessionUpdt.js";
import init_windowEvents from "./init/init_windowEvents.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_enable from "./eventbusHandlers/ebh_swup_enable.js";
import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_currPageId from "./stateChangeHandlers/stChH_currPageId.js";
import stChH_currUserSession from "./stateChangeHandlers/stChH_currUserSession.js";
import stChH_userSessionUpdtIntervalID from "./stateChangeHandlers/stChH_userSessionUpdtIntervalID.js";

import store_userSession from "./api/store_userSession.js";
import delete_userSession from "./api/delete_userSession.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logStyles = { default: "#4361BC", action: "#c7d0ff", event: "#97a5ce", warning: "#ffaf00", error: "#ff3232" };
const trackingRules = [];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default class UserTracker {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: { run_withLogs: false, logStyles },
      trackingRules,
    },
    OPTIONS
  ) {
    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      //////
      name: "UserTracker",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
      //////
      trackingRules: CONFIG?.trackingRules,
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.check_routeToBeTracked = check_routeToBeTracked.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_newUserSession = init_newUserSession.bind(this);
    this.init_sessionUpdt = init_sessionUpdt.bind(this);
    this.init_windowEvents = init_windowEvents.bind(this);

    ///////////////////////// Modules //////////////////////////
    ////////////////////////////////////////////////////////////

    this.modules = {
      // ...
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      store_userSession: store_userSession.bind(this),
      delete_userSession: delete_userSession.bind(this),
    };

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_enable = ebh_swup_enable.bind(this);
    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChH_mode = stChH_mode.bind(this);
    this.stChH_currPageId = stChH_currPageId.bind(this);
    this.stChH_currUserSession = stChH_currUserSession.bind(this);
    this.stChH_userSessionUpdtIntervalID = stChH_userSessionUpdtIntervalID.bind(this);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_state();
    this.init_eventbus();
    this.init_windowEvents();
    this.setState({ initialised: true, mode: "ready" });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_state() {
    this.logger("init", ["state"], "default", { inline: true });
    this.setState({
      mode: "init",
      is_mobile: window.innerWidth <= 640,
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("swup_enable", this.ebl_swup_enable);
    eventbus.on("swup_page_load", this.ebl_swup_page_load);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    // ...
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    /////////////// mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChH_mode(CHANGES);

    ////////////// currPageId ///////////
    ////////////////////////////////////

    if ("currPageId" in CHANGES) this.stChH_currPageId(CHANGES);

    ///////// currUserSession //////////
    ////////////////////////////////////

    if ("currUserSession" in CHANGES) this.stChH_currUserSession(CHANGES);

    // userSessionUpdtIntervalID //
    ////////////////////////////////

    if ("userSessionUpdtIntervalID" in CHANGES) this.stChH_userSessionUpdtIntervalID(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
