// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function store_userSession(SESSIONDATA) {
  return new Promise((resolve, reject) => {
    if (!SESSIONDATA || SESSIONDATA == undefined)
      return console.warn("store_userSession(SESSIONDATA): No user session data provided.");

    //////////////////////
    // In local storage //
    //////////////////////

    localStorage.setItem("UDKAss_userSession", JSON.stringify(SESSIONDATA));

    ///////////
    // In DB //
    ///////////

    // Set up request...
    const request = new XMLHttpRequest();
    const data = new FormData();

    // Prep. request data...
    data.append("sessionID", SESSIONDATA.id);
    data.append("relatedAssemblageId", SESSIONDATA.relatedAssemblageId);
    data.append("lastActive", SESSIONDATA.lastActive.toISOString());

    // Init. + send request...
    request.open("POST", "/api.updt.activeUserSession", true);
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) console.log("%cUser session updtd.", consoleStyles.success);
      if (request.status !== 200) console.log("%cUser session updt. failed.", consoleStyles.error);
      resolve(request.responseText);
    };
    request.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
