// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_dataToRestore_fromStack(RESTOREDATASTACK) {
  if (!RESTOREDATASTACK || RESTOREDATASTACK.length < 1) return console.warn("get_dataToRestore(): no data provided");
  return RESTOREDATASTACK.length > 1 ? RESTOREDATASTACK[RESTOREDATASTACK.length - 2] : { data: {}, timestamp: null };
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_undoBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["undo-btn was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_undoBtn_click() {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!fabricInst) return console.warn("eh_fabricCanvas_mouseDown(): no fabric instance found");

  /////////////////////////////////////
  // Restoration of last canvas data //
  /////////////////////////////////////

  // Clone stored canvas data to prevent mutation...
  const lastCanvasData_copy = structuredClone(this.state.lastCanvasData);
  if (!lastCanvasData_copy || lastCanvasData_copy.length < 2) return console.warn("eh_undoBtn_click(): no lastCanvasData found");

  // Get data to restore (step to which to revert)...
  const dataToRestore = get_dataToRestore_fromStack(lastCanvasData_copy);
  if (!dataToRestore || !dataToRestore.data) return console.warn("eh_undoBtn_click(): no data to restore");

  // Restore data to fabric canvas...
  fabricInst.loadFromJSON(dataToRestore.data, () => fabricInst.renderAll());

  // Remove last step from undo-stack...
  lastCanvasData_copy.pop();
  this.setState({ lastCanvasData: lastCanvasData_copy });

  // Update lastChanged state...
  this.setState({ lastChanged: Date.now() });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
