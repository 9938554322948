// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  disable_openSlotsDisplay: ["Disabling open-slots display updates + hiding open-slots display."],
  disable_onlineUsersDisplay: ["Disabling online-users display updates + hiding online-users display."],
};

const rules_routesWithOnlineUsersDisplay = [{ pattern: "/assemblages/:id" }];
const rules_routesWithOpenSlotsDisplay = [{ pattern: "/assemblages/:id" }];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_page_load(ARGS) {
  // Setup...
  const { _name, to } = ARGS;
  const logArgs = { eventName: "ebh_swup_page_load" };
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // Set new route state...
  const url = to.url;
  const urlParts = url.split("/");
  const pageID = urlParts[urlParts.length - 1];
  const parentPageID = urlParts[urlParts.length - 2];
  const currRoute = { url: to.url, pageID, parentPageID };
  this.setState({ route: currRoute });

  // If applicable, stop open-slots display updates + hide open-slots display...
  if (!this.check_routeMatchesRule(to.url, rules_routesWithOpenSlotsDisplay)) {
    this.logger("eventbus call", loggerMsgs.disable_openSlotsDisplay, "action", { ...logArgs, inline: true });
    clearInterval(this.state.openSlotsUpdtIntervalID);
    this.setState({ openSlotsDisplayState: { is_hidden: true } });
  }

  // If applicable, stop online-users display updates + hide online-users display...
  if (!this.check_routeMatchesRule(to.url, rules_routesWithOnlineUsersDisplay)) {
    this.logger("eventbus call", loggerMsgs.disable_onlineUsersDisplay, "action", { ...logArgs, inline: true });
    clearInterval(this.state.onlineUsersUpdtIntervalID);
    this.setState({ onlineUsersDisplayState: { is_hidden: true } });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
