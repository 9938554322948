// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "./lib/jGia/jGia/src/eventbus";

// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Modules //
import JGiaAppModule from "./modules/baseAppModules/jGia";
import swupAppModule from "./modules/baseAppModules/appMod-swup";
import LazyLoaderAppModule from "./modules/baseAppModules/LazyLoader";
import UserTracker from "./modules/UserTracker/UserTracker.js";

// Components //
import VecDrawTool from "../site/plugins/component-vec-draw-tool/js/VecDrawTool.js";
import LoginModal from "../site/plugins/component-login-modal/js/LoginModal.js";
import Nav from "../site/plugins/component-nav/js/Nav.js";
import Gallery from "../site/plugins/component-gallery/js/Gallery.js";
import OnlineUsersIndicator from "../site/plugins/component-online-users-indicator/js/OnlineUsersIndicator.js";
import TilesetPlayer from "../site/plugins/component-tileset-player/js/TilesetPlayer.js";
import InfoModal from "../site/plugins/component-info-modal/js/InfoModal.js";
import Coulage from "../site/plugins/component-coulage/js/Coulage.js";

const availableComponents = [
  // Global
  // ...

  // Local
  { type: "VecDrawTool", script: VecDrawTool },
  { type: "LoginModal", script: LoginModal },
  { type: "Nav", script: Nav },
  { type: "Gallery", script: Gallery },
  { type: "OnlineUsersIndicator", script: OnlineUsersIndicator },
  { type: "TilesetPlayer", script: TilesetPlayer },
  { type: "InfoModal", script: InfoModal },
  { type: "Coulage", script: Coulage },

  // Other
  // ...
];

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_window_beforeUnload from "./eventHandlers/window/eh_window_beforeUnload.js";
import eh_swup_page_load from "./eventHandlers/swup/eh_swup_page_load";

// Custom event handlers for swup module //
const swupEventHandlers = [
  {
    id: "page:load",
    handler: eh_swup_page_load,
  },
];

// Custom eventbus handlers for swup module //
const swupEventbusConfig = {
  callsToAnswer: ["document_click"],
  handlers: [{ id: "document_click", handler: () => console.log("swup: document was clicked") }],
};

// ————————————————————————————————————————— MODULE INIT. ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

(async () => {
  // Fetch app config...
  const appConfigRequest = await fetch("/app/config.json");
  const appConfig = await appConfigRequest.json();
  const giaConfig = appConfig?.gia;
  const swupConfig = appConfig?.swup;
  const lazyLoaderConfig = appConfig?.lazyLoader;
  const globalComponentConfigs = appConfig?.globalComponents;
  const localComponentConfigs = appConfig?.localComponents;
  const userTrackerConfig = appConfig?.UserTracker;

  // Extend module configs...
  swupConfig.eventbus = swupEventbusConfig;
  swupConfig.eventHandlers = swupEventHandlers;
  swupConfig.useNewJGiaPlugin = true;

  // Create & collect module app module instances...
  const jGiaAppModuleInstance = new JGiaAppModule(giaConfig, globalComponentConfigs, { availableComponents });
  const swupAppModuleInstance = new swupAppModule(swupConfig, giaConfig, { availableComponents }, localComponentConfigs);
  const LazyLoaderAppModuleInstance = new LazyLoaderAppModule(lazyLoaderConfig);
  const appModules = [jGiaAppModuleInstance, swupAppModuleInstance, LazyLoaderAppModuleInstance];

  // Include custom modules...
  if (userTrackerConfig) appModules.push(new UserTracker(userTrackerConfig));

  // Init. app modules...
  try {
    console.log("%cInitialising app modules", consoleStyles.default);
    appModules.forEach((module) => {
      try {
        module.init();
      } catch (error) {
        console.error(`Module "${module.options.name}" failed to initialise.`);
        throw error;
      }
    });
    console.log("%cApp modules initialised", consoleStyles.success);
  } catch (error) {
    console.error(error);
  }
})();

// ————————————————————————————————————————— GLOBAL STATE ————————————————————————————————————————— //
// ——————————————————————————————————————— (local storage ) ——————————————————————————————————————— //

// Create/Set global state object //
console.log("%cSetting global state", consoleStyles.default);
const globalState = { mediaIsEnabled: true, alertBeforeUnload: false };
localStorage.setItem("globalState", JSON.stringify(globalState));

// ———————————————————————————————————————— GLOBAL EVENTS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Event emissions //
window.addEventListener("resize", () => eventbus.emit("window_resize", { windowWidth: window.innerWidth }));
window.addEventListener("beforeunload", eh_window_beforeUnload);
document.documentElement.addEventListener("mouseleave", () => eventbus.emit("document_mouseLeave"));
document.documentElement.addEventListener("click" || "touchstart", (e) => eventbus.emit("document_click", { eventData: e }));

// Eventbus handling //
// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
