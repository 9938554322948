// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_tile_click from "../eventHandlers/eh_tile_click.js";
import eh_deleteButton_click from "../eventHandlers/eh_deleteButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. clickableTiles"],
  initSuccess: ["init. clickableTiles", "init. success"],
  noValidDOMEl: ["init. clickableTiles", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_clickableTiles() {
  // Setup...
  const { clickableTiles, tooltip_tileDeleteBtn } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (clickableTiles.every((el) => !this.validate_refEl(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to mouse events on tiles & tile delete buttons...
  clickableTiles.forEach((el) => {
    el.addEventListener("click", eh_tile_click.bind(this, el));

    // Init. delete button...
    const deleteBtn = el.querySelector("[data-ref='deleteButton']");
    if (!deleteBtn) return console.warn("init_clickableTiles(): No delete button found in tile");
    deleteBtn.addEventListener("click", eh_deleteButton_click.bind(this, el));
    deleteBtn.addEventListener("mouseenter", () => this.updt_tooltip(deleteBtn, tooltip_tileDeleteBtn, { offset: 12 }));
    deleteBtn.addEventListener("mouseleave", () => tooltip_tileDeleteBtn.setAttribute("data-is-hidden", "true"));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
