// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.register_user", inline: true };
const loggerMsgs = {
  eventStart: ["LoginModal", "API", "register_user"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function register_user(
  DATA = {
    newUserID: null,
    assemblageID: null,
  }
) {
  return new Promise((resolve, reject) => {
    if (!DATA || DATA == undefined) reject("register_user(DATA): No data provided.");
    if (!DATA.newUserID || DATA.newUserID == undefined) reject("register_user(DATA): No new user ID provided.");
    if (!DATA.assemblageID || DATA.assemblageID == undefined) reject("register_user(DATA): No assemblage ID provided.");

    // Setup...
    this.logger("event", loggerMsgs.eventStart, "event", logArgs);

    // Show load spinner...
    this.setState({ loadSpinnerHidden: false });

    // Set up request...
    const request = new XMLHttpRequest();
    const data = new FormData();

    // Prep. request data...
    data.append("newUserID", DATA.newUserID);
    data.append("assemblageID", DATA.assemblageID);

    // Init. + send request...
    request.open("POST", "api.assemblage.addParticipant", true);
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) console.log("%cUser registered.", consoleStyles.success);
      if (request.status !== 200) console.log("%cUser registration failed.", consoleStyles.error);
      setTimeout(() => {
        this.setState({ loadSpinnerHidden: true });
        resolve(request.responseText);
      }, 1000);
    };
    request.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
