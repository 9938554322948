// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["close-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_prevNextBtn_click(BUTTONEL, EVENTDATA) {
  // Setup...
  const { slides } = this.ref;
  const { activeSlideSetID, activeSlideIndex } = this.state;
  const relevantSlides = slides.filter((el) => el.dataset.slideSetId === activeSlideSetID);
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Icrement/decrement activeSlideIndex...
  const maxIndex = relevantSlides.length - 1;
  let activeSlideIndex_new = BUTTONEL.dataset.dir === "next" ? activeSlideIndex + 1 : activeSlideIndex - 1;
  if (activeSlideIndex_new === maxIndex + 1) activeSlideIndex_new = 0;
  if (activeSlideIndex_new === -1) activeSlideIndex_new = maxIndex;
  this.setState({ activeSlideIndex: activeSlideIndex_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
