// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_panBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["pan-btn was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_panBtn_click(EVENTDATA) {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  if (!fabricInst) return console.warn("eh_fabricCanvas_objMoving(): no fabric instance found");
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Enable fabric canvas panning...
  if (this.state.mode != "panning") {
    fabricInst.getObjects().forEach((item) => (item.selectable = false));
    this.setState({ mode: "panning" });
  } else {
    fabricInst.getObjects().forEach((item) => {
      item.selectable = true;
      item.setCoords();
    });
    this.setState({ mode: "ready" });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
