// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`route:`, CHANGE],
};

const rules_routesWithBackBtn = [{ pattern: "/assemblages/:id" }];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_route(CHANGES) {
  if (!("route" in CHANGES)) return;

  // Setup...
  const { route } = CHANGES;
  const { url } = route;
  const logArgs = { eventName: "stChH_route" };
  this.logger("state-change", loggerMsgs.stateChange(route), "default", logArgs);

  // Set back-btn. state...
  const hideBackBtn = !this.check_routeMatchesRule(url, rules_routesWithBackBtn);
  this.setState({ backBtnState: { is_hidden: hideBackBtn } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
