// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  init_userTracking: ["new route is to be tracked", "init. user tracking"],
  cancel_routeNotToBeTracked: ["ebh.: swup_page_load", "stopping user tracking", "route not to be tracked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function ebh_swup_page_load(ARGS) {
  // Setup...
  const logArgs = { eventName: "ebh_eventbusCall" };
  const { to } = ARGS;
  const { trackingRules = [] } = this.options;
  const { currUserSession, userSessionUpdtIntervalID } = this.state;
  this.logger("eventbus call", [ARGS], "event", logArgs);

  // Determ. & updt. curr. route/page ID...
  const { url } = to;
  const urlParts = url.split("/");
  const pageID = urlParts[urlParts.length - 1];
  this.setState({ currPageId: pageID });

  // Guard: Match patterns of routes to be tracked (rules) against current route...
  if (this.check_routeToBeTracked(url, trackingRules)) {
    // Init. new user session + session updates...
    this.logger("init", loggerMsgs.init_userTracking, "action", { inline: true });
    await this.init_newUserSession();
    this.init_sessionUpdt();

    // Notify other comp...
    // - Online User Indicator: init. user count display
    eventbus.emit("UserTracker_ready", { pageID });
  } else {
    // Stop user tracking by deleting the curr. user session + clearing the user session updt. interval...
    this.logger("init", loggerMsgs.cancel_routeNotToBeTracked, "warning", { inline: true });
    clearInterval(userSessionUpdtIntervalID);
    this.api.delete_userSession(currUserSession);
    this.setState({ userSessionUpdtIntervalID: "null" });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
